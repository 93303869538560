import Vue from 'vue';

const eventBus = new Vue();

const EventBus = {
    install(VueInstance) {
        if (this.installed) {
            return;
        }

        this.installed = true;

        VueInstance.eventBus = eventBus;
        VueInstance.prototype.$eventBus = eventBus;
    },
};

Vue.use(EventBus);
