<template>
    <activix-modal
        size="xl"
        :name="name"
        :portal="portal"
        :loading="loading"
        :sticky-footer="true"
        ref="modal"
        @close="onClose"
        @opened="onOpened"
        @closed="onClosed"
        @before-open="onBeforeOpen"
    >
        <template slot="header">
            <h4 class="modal-title">
                {{ modalTitle }}
            </h4>
        </template>

        <template slot="body">
            <next-automation-alert :lead="lead" />

            <email-composer-imap-alert :lead="lead" :account="contextAccount" />

            <communicating-alert
                :lead="lead"
                :communicating-type="communicatingType"
                :communicating-user="communicatingUser"
                :recent-communication="recentCommunication"
                :most-recent-communication="mostRecentCommunication"
                :mass-mailing-leads-removed="massMailingLeadsRemoved"
                :mass-mailing-leads-do-not-market="massMailingLeadsDoNotMarket"
                @dismiss="dismiss"
                @close="close"
            />

            <lead-xpress
                :class="{ loading: communicatingUser && !ownCommunication }"
                :communicating="massMailing ? false : communicating"
                :communicating-user="communicatingUser"
                :current-lang="currentLang"
                :draft="draft"
                :is-forwarding="isForwarding"
                :lead="lead"
                :response.sync="response"
                :signature="signature"
                :unsubscribe-link="unsubscribeLink"
                :mass-mailing-lead-count="massMailingLeadCount"
                :add-last-email-body.sync="addLastEmailBody"
                :response-templates="responseTemplates"
                ref="leadXpress"
                @change-lang="changeLanguage"
                @set-initial-body="setInitialBody"
            />
        </template>

        <template slot="footer">
            <div class="flex flex-col justify-between space-y-4 | xs:space-y-0 xs:flex-row">
                <div class="flex space-x-4">
                    <span class="w-full | xs:w-auto">
                        <activix-button class="w-full" @click="reset">
                            <icon :name="$icons.loading" class="mr-2" />
                            {{ $t('mailClient.reset') }}
                        </activix-button>
                    </span>
                    <activix-tooltip :content="deleteDraftTooltip" v-if="draft">
                        <span class="w-full | xs:w-auto">
                            <activix-button
                                class="w-full"
                                :disabled="!!communicatingUser"
                                @click="clearDraft(lead.id)"
                            >
                                <span class="inline-flex items-center text-red-500">
                                    <icon :name="$icons.trash" class="mr-2" />
                                    {{ $t('mailClient.draft') }}
                                </span>
                            </activix-button>
                        </span>
                    </activix-tooltip>
                </div>
                <div class="flex space-x-4">
                    <span class="w-full | xs:w-auto">
                        <activix-button
                            class="w-full"
                            :disabled="!!communicatingUser"
                            @click="triggerSave"
                            v-if="!sending && !massMailing"
                        >
                            <icon class="mr-2" name="regular/pencil-1" />
                            {{ $t('mailClient.draft') }}
                        </activix-button>
                    </span>
                    <activix-tooltip :content="sendTooltip">
                        <span class="w-full | xs:w-auto">
                            <activix-button
                                class="w-full"
                                type="primary"
                                :loading="sending"
                                :disabled="disabledSendButton"
                                @click="send(lead.id)"
                            >
                                <icon class="mr-2" name="regular/send-email" />
                                {{ $t('mailClient.send') }}
                            </activix-button>
                        </span>
                    </activix-tooltip>
                </div>
            </div>
        </template>
    </activix-modal>
</template>

<script>
    import TemplateType from '@/entities/TemplateType.js';

    // Utils
    import { cloneDeep, get } from 'lodash-es';
    import { mapActions, mapState } from 'pinia';
    import { showWarning } from '@/utils/toastr.js';
    import ActivixDate from '@/value-objects/ActivixDate.js';

    // Components
    import LeadXpress from '../lead_xpress/LeadXpress.vue';
    import NextAutomationAlert from '../lead/NextAutomationAlert.vue';
    import CommunicatingAlert from '../CommunicatingAlert.vue';
    import EmailComposerImapAlert from '../EmailComposerImapAlert.vue';

    // Entities
    import CommunicationMethod from '../../entities/CommunicationMethod.js';
    import CommunicationType from '../../entities/CommunicationType.js';
    import Division from '../../entities/Division.js';
    import Lead from '../../entities/Lead.js';
    import EmailValidationRisk from '../../entities/EmailValidationRisk.js';

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useClientCardStore } from '../../store/modules/clientCard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            EmailComposerImapAlert,
            LeadXpress,
            NextAutomationAlert,
            CommunicatingAlert,
        },

        props: {
            name: {
                type: String,
                required: true,
            },
            isForwarding: {
                type: Boolean,
                default: false,
            },
            portal: {
                type: String,
                default: 'modal-1',
            },
        },

        data() {
            return {
                draft: null,
                response: {
                    division: '',
                    template: '',
                    subject: '',
                    to: [],
                    attachments: [],
                    templateAttachments: [],
                    body: '',
                    design: null,
                    cc: [],
                    bcc: [],
                    forwardTo: [],
                    from: '',
                    fromBackup: '',
                    ziggeoId: '',
                    videoProcessed: false,
                },
                landingPageSid: null,
                attachmentErrors: [],
                communicatingUser: null,
                communicatingType: null,
                recentCommunication: null,
                mostRecentCommunication: null,
                massMailingLeadsRemoved: null,
                communicating: false,
                sent: false,
                sending: false,
                lastEmailBody: '',
                currentLang: this.$i18n.locale == 'fr' ? 'FR' : 'EN',
                submitted: false,
                lead: new Lead(),
                leadId: null,
                loading: true,
                addLastEmailBody: false,
                emailConfig: null,
                responseTemplates: [],
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['parentAuthUser', 'taskEventToBeCompleted', 'authUser']),
            ...mapState(useDashboardStore, {
                massMailing: 'isMassMailing',
                dashboardCriteria: 'dashboardCriteria',
                blackListedLeadIds: 'blackListedLeadIds',
                bulkSelect: 'bulkSelect',
                pagination: 'pagination',
                allLeadsInTableSelected: 'allLeadsInTableSelected',
            }),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),
            ...mapState(useClientCardStore, ['replyingEmail']),

            ownCommunication() {
                return this.communicatingUser?.id === this.parentAuthUser?.id || this.communicatingUser?.id === this.authUser.id;
            },

            hasRiskyEmail() {
                return this.response.to.some(email => email.validated_email?.risk == EmailValidationRisk.MEDIUM);
            },

            hasFilteredResponseTemplates() {
                return !!this.responseTemplates.length;
            },

            recording() {
                let status = '';

                if (this.response.ziggeoId) {
                    status = this.response.videoProcessed ? 'processed' : 'uploaded';
                }

                return {
                    ziggeoId: this.response.ziggeoId,
                    status,
                };
            },

            videoCommunication() {
                return this.$ziggeo.getVideoById(this.lead.id) || {};
            },

            hasValidMailOwner() {
                return Number.isInteger(this.response.from) || Number.isInteger(this.response.fromBackup);
            },

            needFromBackup() {
                return !Number.isInteger(this.response.from);
            },

            massMailingLeads() {
                if (!this.massMailing || this.allLeadsInTableSelected) {
                    return [];
                }

                return this.bulkSelect.filter(lead => lead.emails.length > 0 && !lead.do_not_market);
            },

            massMailingLeadsDoNotMarket() {
                if (!this.massMailing || this.allLeadsInTableSelected) {
                    return [];
                }

                return this.bulkSelect.filter(lead => lead.do_not_market);
            },

            massMailingLeadCount() {
                if (!this.massMailing) {
                    return 0;
                }

                if (!this.allLeadsInTableSelected) {
                    return this.massMailingLeads.length;
                }

                return !this.massMailingLeadsRemoved
                    ? this.pagination.total - this.blackListedLeadIds.length - this.massMailingLeadsDoNotMarket.length
                    : this.pagination.total - this.massMailingLeadsRemoved.length - this.blackListedLeadIds.length - this.massMailingLeadsDoNotMarket.length;
            },

            canSaveDraft() {
                const emptyBody = !this.response.body || this.response.body == this.initialBody;

                if (this.massMailing || this.sending || this.sent || !this.lead.id) {
                    return false;
                }

                if (!this.response.subject && emptyBody) {
                    return false;
                }

                return !(
                    this.draft &&
                    this.draft.body == this.response.body &&
                    this.draft.subject == this.response.subject &&
                    this.draft.from == this.response.from &&
                    this.draft.cc == this.response.cc &&
                    this.draft.bcc == this.response.bcc &&
                    this.draft.media?.ziggeo_id == this.response.ziggeoId &&
                    this.draft.media?.video_processed == this.response.videoProcessed
                );
            },

            initialBody() {
                let body = `
                    <div class="body"></div>
                    <p><br><br></p>
                    ${this.signature}
                `;

                if (this.unsubscribeLink) {
                    body += `
                        <div><br><hr></div>
                        ${this.unsubscribeLink}
                   `;
                }

                body += '<div class="original-communication"></div>';

                return body;
            },

            unsubscribeLink() {
                if (
                    !this.emailConfig ||
                    (this.emailConfig.active && !this.massMailing)
                ) {
                    return '';
                }

                const accountPhone = this.formatPhone(this.currentUser.account.phone, true);

                let unsubscribeText = '<a href="%tag_unsubscribe_url%" style="text-decoration: none;">';

                if (this.currentLang == 'EN') {
                    unsubscribeText += `
                        Click here</a> to stop receiving communications from ${this.currentUser.account.name} or contact us at ${accountPhone} to be removed from our list.
                    `;
                } else {
                    unsubscribeText += `
                        Cliquez ici</a> pour ne plus recevoir de communications de la part de ${this.currentUser.account.name} ou communiquez avec nous au ${accountPhone} afin d'être retiré de notre liste.
                    `;
                }

                return `
                    <div class="unsubscribe-link" style="color: #B0B0B0; font-size: 12px;">
                        ${unsubscribeText}
                    </div>
                `;
            },

            authorizedToSend() {
                if (this.massMailing) {
                    return (
                        !empty(this.response.subject) &&
                        (!empty(this.response.body) &&
                            !this.fallbackOwnerShouldBeVisible ||
                            !this.sent &&
                            empty(this.attachmentErrors) &&
                            !!this.massMailingLeadCount)
                    );
                }

                if (!empty(this.response.template) && this.hasFilteredResponseTemplates) {
                    return (
                        !empty(this.response.subject) &&
                        !empty(this.response.to) &&
                        !empty(this.response.body) &&
                        empty(this.attachmentErrors) &&
                        !this.sent
                    );
                }

                return (
                    !empty(this.response.subject) &&
                    !empty(this.response.to) &&
                    !empty(this.response.body) &&
                    empty(this.attachmentErrors) &&
                    this.response.cc.every(cc => this.emailIsValid(cc)) &&
                    this.response.bcc.every(bcc => this.emailIsValid(bcc)) &&
                    !this.sent
                );
            },

            currentUser() {
                return this.parentAuthUser.childUserForAccount(this.lead.account_id) || this.authUser;
            },

            templateAdvanced() {
                return (this.response.template?.is_advanced && this.response.template?.design) || this.response.design;
            },

            signature() {
                if (!this.templateAdvanced && this.response.from != this.currentUser.id) {
                    return `
                        <div class="signature">
                            <h4 class="name">
                                <div contentEditable="false" class="max-w-sm w-full">
                                    <div class="flex space-x-4">
                                        <div class="flex-1 space-y-4 py-1">
                                            <div class="h-9 bg-gray-200 rounded w-3/4 mb-3"></div>
                                            <div class="space-y-2">
                                                <div class="h-4 bg-gray-200 rounded mb-2"></div>
                                                <div class="h-4 bg-gray-200 rounded w-5/6"></div>
                                            </div>
                                            ${this.accountLogo}
                                        </div>
                                    </div>
                                </div>
                            </h4>
                        </div>
                    `;
                }

                const key = `signature_${this.currentLang.toLowerCase()}`;
                const signature = get(this.emailConfig, key);

                if (!signature) {
                    return '';
                }

                return `
                    <div class="signature">
                        ${signature}
                        ${this.accountLogo}
                    </div>
                `;
            },

            accountLogo() {
                const logoSource = this.currentLang == 'FR' ? 'logo' : 'logo_en';

                const logo =
                    get(this.currentLead, `account.${logoSource}`) || get(this.currentUser, `account.${logoSource}`);

                if (!logo) {
                    return '';
                }

                return `<img src="${logo}" style="max-width: 400px; max-height:125px">`;
            },

            modalTitle() {
                return this.$t('mailClient.composerMessage');
            },

            disableSendReasons() {
                const reasons = [];

                if (!this.authorizedToSend) {
                    reasons.push('notAuthorizedToSend');
                }
                if (this.sending) {
                    reasons.push('sending');
                }
                if (this.communicating && !this.ownCommunication) {
                    reasons.push('communicating');
                }
                if (this.mostRecentCommunication) {
                    reasons.push('hasMostRecentCommunication');
                }
                if (!this.hasValidMailOwner) {
                    reasons.push('hasNoValidMailOwner');
                }
                if (this.isMassMailing && this.massMailingLeadCount === 0) {
                    reasons.push('hasNoRecipient');
                }
                if (
                    !empty(this.response.division) &&
                    empty(this.response.template) &&
                    this.hasFilteredResponseTemplates
                ) {
                    reasons.push('emptyTemplate');
                }
                if (['uploading', 'uploaded'].includes(this.videoCommunication.status)) {
                    reasons.push('videoUploading');
                }

                return reasons;
            },

            sendTooltip() {
                const messages = [];

                if (this.disableSendReasons.includes('notAuthorizedToSend')) {
                    if (empty(this.response.from)) {
                        messages.push(this.$t('mailClient.emptySenderTooltip'));
                    }

                    if (empty(this.response.subject)) {
                        messages.push(this.$t('mailClient.emptySubjectTooltip'));
                    }

                    if (empty(this.response.body)) {
                        messages.push(this.$t('mailClient.emptyBodyTooltip'));
                    }
                }

                if (this.disableSendReasons.includes('hasNoValidMailOwner')) {
                    messages.push(this.$t('mailClient.emptyFallbackSenderTooltip'));
                }

                if (this.disableSendReasons.includes('hasNoRecipient')) {
                    messages.push(this.$t('mailClient.emptyRecipientTooltip'));
                }

                if (this.disableSendReasons.includes('emptyTemplate')) {
                    messages.push(this.$t('mailClient.emptyResponseTemplateTooltip'));
                }

                if (this.disableSendReasons.includes('videoUploading')) {
                    messages.push(this.$t('mailClient.videoUploading'));
                }

                return messages.join('<br>');
            },

            deleteDraftTooltip() {
                if (!this.draft) {
                    return '';
                }

                const draftDate = new ActivixDate(this.draft.created_at).toHumanShort();

                return `
                    <span class="font-semibold">${this.$t('mailClient.draftDate')}</span>
                    <br>
                    ${draftDate}
                `;
            },

            disabledSendButton() {
                return this.disableSendReasons
                    .filter(reason => {
                        switch (reason) {
                            case 'emptyTemplate': return false;
                            default: return true;
                        }
                    })
                    .length > 0;
            },

            query() {
                if (this.allLeadsInTableSelected) {
                    return { search: this.dashboardCriteria };
                }

                if (this.bulkSelect.length && this.massMailingLeads.length) {
                    return { ids: this.massMailingLeads.map(lead => lead.id) };
                }

                return { id: this.leadId };
            },
        },

        watch: {
            'currentUser.id': {
                immediate: true,
                async handler() {
                    this.emailConfig = await this.currentUser.email_config;
                },
            },

            '$i18n.locale'(newLocale) {
                this.currentLang = newLocale == 'fr' ? 'FR' : 'EN';
            },

            massMailingLeadsRemoved(newValue) {
                let tmpLeads = cloneDeep(this.bulkSelect);

                if (!empty(newValue)) {
                    for (let i = newValue.length - 1; i >= 0; i--) {
                        tmpLeads = tmpLeads.filter(tmpLead => tmpLead.id != newValue[i].id);
                    }
                }

                this.setBulkSelect(tmpLeads);
            },

            currentLang() {
                this.reset(true);
            },

            massMailing(newValue) {
                if (newValue) {
                    this.communicating = false;
                }
            },

            videoCommunication() {
                this.response.ziggeoId = this.videoCommunication.ziggeoId;
                this.response.videoProcessed = this.videoCommunication.status === 'processed';
            },
        },

        methods: {
            ...mapActions(useDashboardStore, [
                'setBulkSelect',
                'setAllLeadsInTableSelected',
            ]),
            ...mapActions(useContextStore, ['setContextLeadIdAction']),
            ...mapActions(useGlobalStore, ['updateLeadAction', 'fetchLead', 'taskEventUpdated', 'appendNewError', 'setTaskEventToBeCompleted']),

            async setLastEmailBody() {
                if (this.massMailing || !this.lead.communications.length) {
                    this.lastEmailBody = '';
                    return;
                }

                const communications = this.lead.communications.filter(
                    c => c.communication_method_id == CommunicationMethod.EMAIL,
                );
                const latestCommunication = communications[communications.length - 1];

                if (!latestCommunication) {
                    this.lastEmailBody = '';
                    return;
                }

                const response = await this.$axios.get(`v1/communications/${latestCommunication.id}`, {
                    include: 'user',
                });

                const communication = response.data.data;

                if (communication.lead_id != this.lead.id) {
                    this.lastEmailBody = '';
                    return;
                }

                let senderName = this.lead.fullName;
                let senderEmail = communication.client_email ? `&lt;${communication.client_email}&gt;` : '';

                if (communication.communication_type_id == CommunicationType.OUTGOING) {
                    senderName = this.getFullName(communication.user);
                    senderEmail = communication.user_email ? `&lt;${communication.user_email}&gt;` : '';
                }

                if (senderName) {
                    senderName += ' ';
                }

                const date = new ActivixDate(communication.created_at).toHumanShort();

                this.lastEmailBody = `
                    <div class="last-email-body">
                        ${this.$t('email.on')} ${date}, ${senderName}${senderEmail}, ${this.$t('email.wrote')}:<br>
                        <blockquote type="cite">
                            ${communication.email_body}
                        </blockquote>
                    </div>
                `;
            },

            async deleteDraft(leadId) {
                this.draft = null;

                if (leadId) {
                    this.$axios.delete(`v1/leadxpress/draft/${leadId}`);
                }

                if (!this.sent && ['uploading', 'uploaded', 'processed'].includes(this.videoCommunication.status)) {
                    await this.$ziggeo.deleteVideo(this.lead.id, this.videoCommunication.ziggeoId);
                }
            },

            clearDraft(leadId) {
                this.deleteDraft(leadId);

                this.reset();
            },

            setSubject() {
                const prefix = this.isForwarding ? 'Fw:' : 'Re:';

                const communications = this.lead.communications;

                const communication = communications.reverse().find(communication => {
                    return (
                        !empty(communication.email_subject) &&
                        communication.communication_method_id == CommunicationMethod.EMAIL &&
                        communication.communication_type_id == CommunicationType.INCOMING
                    );
                });

                if (!communication) return prefix;

                return `${prefix} ${communication.email_subject}`;
            },

            setInitialBody() {
                this.response.body = this.initialBody;
            },

            reset($event, soft = false) {
                this.response.division = '';
                this.response.template = '';

                if (!soft) {
                    this.response.subject = '';
                    this.response.attachments = [];
                    this.response.templateAttachments = [];
                    this.response.body = this.initialBody;
                    this.response.videoHash = '';
                    this.response.to = [];
                    this.response.cc = [];
                    this.response.bcc = [];
                    this.response.from = this.currentUser.id;
                    this.response.fromBackup = '';
                    this.response.ziggeoId = '';
                    this.response.videoProcessed = false;
                    this.response.design = '';
                }

                this.sent = false;
                this.draft = null;
                this.addLastEmailBody = false;
                this.$refs.leadXpress?.updateDefault();
            },

            displaySuccessNotification() {
                if (this.hasRecipient) {
                    this.$notify.success(this.$t('email.success_mass_mailing'));
                } else {
                    this.$notify.success(this.$t('email.success'));
                }
            },

            async createLandingPage() {
                const landingPageResponse = await this.$ziggeo.createLandingPage(
                    this.lead.id,
                    this.currentUser.id,
                    {
                        title: this.response.subject,
                        leadSid: this.lead.sid,
                        accountSid: this.lead.account.sid,
                    },
                );

                this.landingPageSid = landingPageResponse.data.data.sid;
            },

            async send(leadId, retryCount = 0) {
                if ((this.sending && retryCount === 0) || (!this.massMailing && !leadId)) {
                    return;
                }

                this.sending = true;
                this.submitted = true;

                if (['uploaded', 'processed'].includes(this.videoCommunication.status)) {
                    try {
                        await this.createLandingPage();
                        await this.$ziggeo.updateZiggeoVideo(
                            this.videoCommunication.ziggeoId,
                            { tags: 'sent' },
                        );
                        this.$ziggeo.resetVideo(this.lead.id);
                    } catch (error) {
                        if (error.response && error.response.status === 403) {
                            this.close();
                        }

                        this.$notify.error(this.$t('communications.video.statusMessage.videoCommunicationFail'));

                        this.response.videoProcessed = false;
                        this.response.ziggeoId = '';
                        this.sending = false;
                        this.submitted = false;
                        this.sent = false;

                        return;
                    }
                }

                const payload = new FormData();
                const data = {};

                // Send email
                data.subject = this.response.subject;
                data.isForwarding = this.isForwarding;

                if (this.isForwarding) {
                    data.to = [];
                    data.forwardTo = this.response.forwardTo;
                } else {
                    data.to = !this.massMailing ? this.response.to.map(email => email.id) : [];
                    data.forwardTo = [];
                }

                data.body = this.response.body;

                if (this.addLastEmailBody && this.lastEmailBody) {
                    data.body += `
                        <div><br></div>
                        ${this.lastEmailBody}
                    `;
                }

                data.currentLang = this.currentLang.toLowerCase();
                data.cc = this.response.cc;
                data.bcc = this.response.bcc;
                data.videoCommunication = {
                    ziggeoId: this.response.ziggeoId,
                    landingPageSid: this.landingPageSid,
                };

                // Save draft if email send failed
                data.division = this.response.division;
                data.template = this.response.template;
                data.response_template_id = !empty(this.response.template) ? this.response.template.id : null;
                data.locale = this.currentLang.toLowerCase();

                data.from = this.response.from;

                if (this.needFromBackup) {
                    data.from_backup = this.response.fromBackup;
                }

                payload.append('data', JSON.stringify(data));
                payload.append('accountId', this.contextAccount.id);

                // Add Attachments
                this.response.attachments.forEach(attachment => {
                    if (attachment.id) {
                        payload.append('existingAttachments[]', JSON.stringify(attachment));
                    } else {
                        payload.append('attachments[]', attachment);
                    }
                });

                if (this.allLeadsInTableSelected) {
                    this.blackListedLeadIds.forEach(blackListedLeadId => {
                        payload.append('blackListedLeadIds[]', blackListedLeadId);
                    });
                }

                // Send Email
                try {
                    await this.$api.leads.email(this.query, payload);

                    this.sent = true;

                    if (!this.massMailing) {
                        // Delete Draft
                        this.deleteDraft(leadId);
                    }

                    // Show success
                    this.displaySuccessNotification();

                    // Complete taskEvent if necessary
                    if (this.taskEventToBeCompleted) {
                        await this.$axios.post(`v1/reminder/done/${this.taskEventToBeCompleted}`).then(({ data }) => {
                            if (!data.success) {
                                return;
                            }

                            this.taskEventUpdated(data.taskEvent);
                        });
                    }

                    this.setContextLeadIdAction(leadId, true);

                    this.$nextTick(() => {
                        // Close modal
                        this.close();

                        this.sending = false;
                    });
                } catch (error) {
                    const leadCount = this.massMailing ? this.massMailingLeadCount : 1;

                    if (error.response) {
                        switch (error.response.status) {
                            case 400:
                                showWarning(
                                    this.$tc('emailLead.invalidEmail', leadCount, { lead: this.lead.fullName }),
                                );
                                this.sent = false;
                                this.sending = false;
                                return;

                            case 403:
                                showWarning(this.$tc('emailLead.cantSendEmail', leadCount));
                                this.sent = false;
                                this.sending = false;
                                return;

                            case 406:
                                showWarning(this.$t('emailLead.unsubscribeRequired'));
                                this.sent = false;
                                this.sending = false;
                                return;

                            case 422:
                                showWarning(this.$t('emailLead.cantSendSameEmail'));
                                this.sent = false;
                                this.sending = false;
                                return;
                        }

                        this.appendNewError({
                            code: '0099',
                            display: true,
                            error,
                            request: {
                                payload,
                            },
                        });

                        this.sent = false;
                    } else if (String(error).includes('Error: Network Error')) {
                        showWarning(this.$t('toastr.errorNetwork'), 0);

                        this.sent = false;
                    } else {
                        // Show success for the sent because the error occured after the request
                        this.displaySuccessNotification();

                        this.close();
                    }

                    this.sending = false;
                }
            },

            triggerSave() {
                this.submitted = true;
                this.close();
            },

            changeLanguage(event) {
                this.currentLang = event;
            },

            async saveDraft() {
                if (!this.canSaveDraft) {
                    return;
                }

                const payload = {
                    division: this.response.division,
                    subject: this.response.subject,
                    to: this.response.to,
                    cc: this.response.cc,
                    bcc: this.response.bcc,
                    from: this.response.from,
                    body: this.response.body,
                    video_processed: this.response.videoProcessed || this.videoCommunication.status === 'processed',
                    ziggeo_id: this.response.ziggeoId || this.videoCommunication.ziggeoId,
                    design: this.response.design,
                    template: this.response.template,
                    templateAttachments: this.response.templateAttachments,
                    locale: this.currentLang.toLowerCase(),
                };

                try {
                    const draft = await this.$axios.put(`v1/leadxpress/draft/${this.lead.id}`, payload);

                    if (['uploaded', 'processed'].includes(this.videoCommunication.status)) {
                        await this.$ziggeo.updateZiggeoVideo(
                            this.videoCommunication.ziggeoId,
                            {
                                data: {
                                    account_sid: this.authUser.account.sid,
                                    user_id: this.authUser.id,
                                    lead_id: this.lead.id,
                                    draft_id: draft.data.data.id,
                                },
                            },
                        );
                    }
                } catch (error) {
                    this.appendNewError({
                        code: '0100',
                        display: false,
                        error,
                        payload,
                    });
                }
            },

            async fetchDraft(leadId) {
                try {
                    const response = await this.$axios.get(`v1/leadxpress/draft/${leadId}`);

                    const draft = response.data.data;

                    if (!draft || draft.lead_id != leadId) {
                        return;
                    }

                    if (draft.body != this.response.body || !empty(draft.response_template_id)) {
                        this.draft = draft;
                    }

                    this.currentLang = draft.locale.toUpperCase();

                    // Template
                    if (!empty(draft.response_template)) {
                        this.response.templateAttachments = draft.response_template.response_template_attachments;

                        draft.response_template.response_template_translations.forEach(translation => {
                            if (translation.locale != this.currentLang.toLowerCase()) {
                                return;
                            }

                            this.response.template = {
                                id: draft.response_template.id,
                                subject: translation.subject,
                                body: translation.body,
                                title: translation.title,
                            };
                        });
                    }

                    if (draft?.media?.ziggeo_id) {
                        const tempMedia = draft.media;
                        const response = await this.$ziggeo.getVideoStateFromZiggeo(draft.media.ziggeo_id);
                        draft.media = null;

                        response.success(() => {
                            this.response.videoProcessed = draft.media?.video_processed;
                            this.response.ziggeoId = draft.media?.ziggeo_id;
                            draft.media = tempMedia;
                            this.draft.media = tempMedia;
                        });
                    }

                    // Required to make sure it overwrites template automation
                    this.$nextTick(() => {
                        if (!empty(draft.division)) {
                            draft.division.locale_name = Division.getTranslation(draft.division.id);
                        }

                        this.response.division = draft.division;
                        this.response.to = draft.lead_emails;
                        this.response.cc = draft.cc;
                        this.response.bcc = draft.bcc;
                        this.response.design = draft.design;
                        this.response.from = parseInt(draft.from, 10) || this.currentUser.id;

                        if (!this.replyingEmail) {
                            this.response.subject = draft.subject;
                        }

                        this.response.body = draft.body;
                        this.draft = draft;
                    });
                } catch (error) {
                    if (!error.response || error.response.status == 422) {
                        return;
                    }

                    this.appendNewError({
                        code: '0101',
                        display: false,
                        error,
                    });
                }
            },

            async removeCommunicating() {
                if (this.massMailing) {
                    return;
                }

                await this.$api.leads.removeCommunicating(this.query, {
                    blackListedLeadIds: this.allLeadsInTableSelected ? this.blackListedLeadIds : [],
                });
            },

            async setCommunicating() {
                if (!this.$refs.modal?.exists) {
                    return;
                }

                this.communicating = false;

                const response = await this.$api.leads.setCommunicating(this.query, {
                    type: CommunicationMethod.EMAIL,
                    blackListedLeadIds: this.allLeadsInTableSelected ? this.blackListedLeadIds : [],
                });

                const { data } = response.data;

                if (!this.massMailing) {
                    if (data.user !== false) {
                        this.massMailingLeadsRemoved = null;
                        this.communicating = true;
                        this.communicatingUser = data.user;
                        this.communicatingType = data.type;
                        this.recentCommunication = null;
                    } else if (data.communication !== false) {
                        this.massMailingLeadsRemoved = null;
                        this.communicating = false;
                        this.communicatingUser = null;
                        this.communicatingType = null;
                        this.recentCommunication = data.communication;
                    } else {
                        this.massMailingLeadsRemoved = null;
                        this.communicating = false;
                        this.communicatingUser = null;
                        this.communicatingType = null;
                        this.recentCommunication = null;
                    }
                } else if (Array.isArray(data.leads?.oldCommunicating) && data.leads?.oldCommunicating.length) {
                    this.massMailingLeadsRemoved = data.leads.oldCommunicating;
                    this.communicating = false;
                    this.communicatingUser = null;
                    this.communicatingType = null;
                    this.recentCommunication = null;
                } else {
                    this.massMailingLeadsRemoved = null;
                    this.communicating = false;
                    this.communicatingUser = null;
                    this.communicatingType = null;
                    this.recentCommunication = null;
                }
            },

            // Check if there has been communication with the email before, if so display options
            async checkOldCommunication() {
                if (!this.$refs.modal?.exists) {
                    return;
                }

                const response = await this.$axios.get(`v1/leadxpress/check-old-communication/${this.lead.id}`);

                if (!empty(response.data.mostRecentCommunication)) {
                    this.mostRecentCommunication = response.data.mostRecentCommunication;
                }
            },

            async loadResponseTemplates() {
                if (!this.contextAccount.responseTemplatesLoaded) {
                    await this.contextAccount.loadResponseTemplates();
                }

                this.responseTemplates = this.contextAccount.responseTemplates.filter(template => {
                    return (
                        template.type === TemplateType.EMAIL &&
                        template.translations.length
                    );
                });
            },

            dismiss() {
                this.mostRecentCommunication = null;
            },

            onClose() {
                this.removeCommunicating();
                this.saveDraft();
            },

            close() {
                this.$refs.modal.hide();
            },

            async onOpen(leadId) {
                if (leadId && this.massMailing) {
                    this.setAllLeadsInTableSelected(false);
                }

                await this.setLastEmailBody();

                this.reset();

                if (this.replyingEmail) {
                    this.response.subject = this.setSubject();
                    this.addLastEmailBody = true;
                }

                const promises = [];

                promises.push(this.loadResponseTemplates());

                if (!this.massMailing) {
                    this.communicating = true;

                    promises.push(this.setCommunicating());
                    promises.push(this.checkOldCommunication());
                    promises.push(this.fetchDraft(leadId));
                }

                await Promise.all(promises);
            },

            onOpened() {
                this.currentLang = (this.lead.locale || this.$i18n.locale).toUpperCase();

                // iFrame
                const iframe = $(this.$refs.leadXpress.$refs.iframe);

                if (iframe.length != 0) {
                    if (typeof iframe[0] !== 'undefined' && typeof iframe[0].iFrameResizer !== 'undefined') {
                        iframe[0].iFrameResizer.resize();
                    } else {
                        iframe.iFrameResize({ autoResize: false });
                    }
                }
            },

            onClosed() {
                if (this.submitted && this.sent) {
                    this.massMailingLeadsRemoved = null;
                    this.communicatingUser = null;
                    this.communicatingType = null;
                    this.recentCommunication = null;
                    this.mostRecentCommunication = null;
                    this.communicating = false;
                    this.lastEmailBody = '';

                    if (this.taskEventToBeCompleted) {
                        this.setTaskEventToBeCompleted(null);
                    }

                    // Open Next Step if necessary
                    if (!this.massMailing) {
                        this.$eventBus.$emit('show-next-step', { lead: this.lead });
                    }

                    this.setAllLeadsInTableSelected(false);
                }

                this.submitted = false;
                this.leadId = null;
                this.lead = new Lead();
                this.loading = true;
                useClientCardStore().replyingEmail = false;
            },

            async onBeforeOpen(leadId) {
                if (leadId) {
                    this.leadId = leadId;
                    this.lead = await this.fetchLead(leadId);
                }

                await this.onOpen(leadId);
                this.loading = false;
            },
        },
    };
</script>
