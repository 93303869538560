import hmacMd5 from 'crypto-js/hmac-md5.js';
import { mapState } from 'pinia';
import { useGlobalStore } from '../store/store.js';

export default {
    computed: {
        ...mapState(useGlobalStore, ['authUser']),
    },

    methods: {
        getFreshdeskUrl() {
            if (this.authUser.locale == 'en') {
                return process.env.VUE_APP_SUPPORT_URL;
            }

            const currentTimestamp = now().timestamp();
            const freshdeskShareSecret = process.env.VUE_APP_FRESHDESK_SHARED_SECRET;
            const freshdeskBaseUrl = process.env.VUE_APP_FRESHDESK_BASE_URL;

            const name = this.authUser.fullName;
            const email = this.authUser.email;
            const locale = this.authUser.locale;

            const hash = hmacMd5(`${name}${freshdeskShareSecret}${email}${currentTimestamp}`, freshdeskShareSecret);

            return `${freshdeskBaseUrl}/login/sso/?name=${name}&email=${email}&timestamp=${currentTimestamp}&hash=${hash}&redirect_to=/${locale}/support/home`;
        },

        openFreshdesk(inNewTab = true) {
            if (inNewTab) {
                window.open(this.getFreshdeskUrl(), '_blank');
            } else {
                window.location.replace(this.getFreshdeskUrl());
            }
        },
    },
};
