export default {
    account: 'Account',
    action: 'Action',
    activateConfirmationApptService: 'Enabling this option will result in sending your service appointment confirmation in double since it is also sent by your DMS. @:general.wishToProceed',
    additionalNotes: 'Additional notes',
    addComment: 'Add a note',
    addCommentOrUpload: 'Add a note or drop a file here',
    addCommentMentions: 'Mention',
    addCommentMentionsDisabled: 'You cannot mention while it is a private note',
    addCommunication: 'Add a communication',
    addLead: 'Add new lead',
    addNewLead: 'You cannot add a current vehicle for @:lexicon.portfolio.prefix, please add a new @:lexicon.portfolio.singular lead instead.',
    addOriginalEmail: 'Original email',
    addPrecision: 'Please specify',
    address: 'Address',
    addVehicle: 'Add a vehicle',
    addVisitWithoutAppointmentWarning: 'It is strongly recommended that you book an appointment to avoid altering the statistics between the number of visits and the number of appointments.<br><br>Are you sure you want to continue?',
    advisor: 'Associate',
    alertInvalidStatus: 'You cannot invalidate the lead because an Appt. / Visit / @.capitalize:lexicon.sale.singular / @.capitalize:lexicon.delivery.singular is planned',
    noAccessToSelectOption: 'You do not have access to select this option',
    alertSent: 'Alert sent',
    all: 'All',
    alreadyExported: 'Already exported',
    answered: 'Answered',
    appointment: 'Appointment',
    approved: 'Approved',
    assignationDontAllow: 'You can\'t assign yourself. Please make an assign request.',
    assignationRequest: 'Assignment request',
    assignationsAndDates: 'Assignments & Dates',
    assignedRequestAdvisor: '{user} made an assignment request. It is pending approval',
    assignedRequestNeedToBeApprovedAdvisor: '{user} made an assignment request. Approve / reject it by clicking on the associate\'s name',
    assignedRequestNeedToBeApprovedBdc: '{user} made an assignment request. Approve / reject it by clicking on the agent\'s name',
    associate: 'Associate',
    associateLead: 'Associate',
    associatingLead: 'Associating the lead',
    association: 'Association',
    attachFiles: 'Attach files',
    attachFilesCount: '{count} file selected | {count} files selected',
    attachments: 'Attachments',
    attemped: 'Attemped',
    attempted: 'Attempted',
    audio: 'Audio',
    automatedEmail: 'Automated email',
    automatedSms: 'Automated Sms',
    availableDate: 'Veh. here on',
    basicInfo: 'Basic information',
    bdc: 'BDC Agent',
    bdcAgent: 'BDC Agent',
    beBack: 'B-back',
    bestMatch: 'Best match',
    birthDate: 'Date of birth',
    blockedPhoneModification: 'This phone is linked to a NioText conversation and cannot be deleted. Do you want to modify this phone?',
    business: 'Business',
    businessTooltip: 'Business Client',
    call: 'Call',
    callDate: 'Call date',
    callDescription: 'Call description',
    cannotAddVehicleRenewal: 'Impossible action',
    cantAddAudioNoteWithText: 'You can\'t add audio on a note containing text',
    cantAssociateEventLead: 'You can\'t dissociate an event lead',
    cantAssociateLeadWithEventRelated: 'You can\'t dissociate a lead related to an event lead',
    cantSelectActualLead: 'Can\'t select current lead',
    cantChangeDivisionLeadType: 'To modify the division, you first need to change the "{0}" lead type because it is only valid for the "{1}" division.',
    cantChangeDivisionSoldProducts: 'You cannot change the "{0}" division when products have already been @:lexicon.sold.singular .',
    cantUncheckOnWalkIn: 'The visit cannot be unchecked on @:lexicon.walkIn.prefix',
    carIsAlreadySold: 'This lead already has a @:lexicon.sale.singular',
    cases: 'Cases',
    certified: 'Certified',
    certifiedTooltip: 'Certified Used Vehicle',
    changeValueCBB: 'This value was defined by Canadian Black Book, are you sure you wish to modify it?',
    changeValueCBBTitle: 'Value defined by Canadian Black Book',
    chooseAssociationRule: 'Please choose the association rule',
    chooseDeliveredVehicle: 'Please select the @:lexicon.delivered.singular vehicle',
    chooseDeliveryMan: 'Choose a @:lexicon.delivery.singular specialist',
    chooseDirector: 'Choose a manager',
    chooseFusionRule: 'Please choose the merge rule',
    chooseRecordedVehicle: 'Please select the reported vehicle',
    chooseRefinancedVehicle: 'Please select the refinanced vehicle',
    chooseRenewalVehicle: 'Please select the @:lexicon.renewed.singular vehicle',
    chooseServiceVehicle: 'Please select the vehicle for the servicing',
    chooseSoldVehicle: 'Please select the @:lexicon.sold.singular vehicle',
    chooseVehicleToExport: 'Please select the vehicle to export',
    chooseCdkDriveExportOptions: 'Please select CDK Drive deal export options',
    chooseSupplierExportOptions: 'Please select {0} export options',
    city: 'City',
    civility: '@.capitalize:lexicon.civility.singular',
    clickOpenNewTab: 'Open link in new tab',
    client: 'Client',
    clientEmail: 'CLIENT EMAIL',
    clientIsAllUnsubscribed: 'The client doesn\'t want to be contacted until {0}',
    clientIsAllUnsubscribedForever: 'The client doesn\'t want to be contacted',
    clientIsCallUnsubscribed: 'The client unsubscribed from call',
    clientIsEmailUnsubscribed: 'The client unsubscribed from email',
    clientIsSmsUnsubscribed: 'The client unsubscribed from SMS',
    clientNumber: 'Client #',
    clientType: 'Client type',
    close: 'Close',
    code: 'Code',
    comment: 'Notes',
    commercial: 'F&I',
    commercialShort: 'F&I',
    communicationCreatedByCrmDisabledTooltip: 'These fields cannot be changed on a CRM communication',
    communicationMethod: 'Method',
    communicationPreference: 'Preference',
    communicationPreferenceTooltip: 'Communication preference',
    communications: 'Communications with the client',
    communicationType: 'Communication type',
    contactType: {
        home: 'Home Contact',
        work: 'Work Contact',
    },
    crmCreatedAt: 'Added to CRM on {date}',
    businessName: 'Business name',
    casl: 'Communication consent',
    complete: 'Complete',
    completingLead: 'Completing the lead',
    contact: 'Contact',
    contactDms: 'Contact :',
    correspondingLeads: 'List of corresponding leads',
    country: 'Country',
    createdAt: 'Created',
    createdBy: 'Created by',
    createLead: 'Create a new lead',
    creatingLead: 'Creating the lead',
    csi: 'CSI',
    csiCompleted: 'CSI completed',
    customerEmail: 'Customer Email',
    date: 'Date',
    dateTime: 'Date & time',
    dealerTour: 'Dealer Tour',
    delete: 'Delete',
    deleteCommunicationConfirm: 'This communication will be deleted permanently',
    deletedAt: 'Deleted At',
    deletedBy: 'Deleted By',
    deleteLead: 'Delete lead',
    deleteVehicle: 'Delete this vehicle',
    deleteVehicleConfirm: 'Are you sure that you want to delete this vehicle?',
    deleteVehicleSubConfirm: 'You will not able to retrieve it!',
    deliverableDate: 'Veh. @:lexicon.deliverable.singular on',
    delivered: '@.capitalize:lexicon.delivered.singular',
    deliveredBy: '@.capitalize:lexicon.delivered.singular by',
    deliveryDate: '@.capitalize:lexicon.deliveryDate.singular',
    deliveryPrepDate: 'Veh. here on',
    deposit: 'Deposit',
    vehicleNoStockNumberOrVinText: 'Only vehicles with a VIN or stock # will be exported. Do you want to continue?',
    vehicleNoStockNumberOrVinTitle: 'VIN or stock # required',
    discounted: 'Funded | Funded',
    discountedMustBeActive: 'The Funded option must be active in the account to have access to this option',
    dissociateCurrentLeadOnly: 'Dissociate current lead only',
    division: 'Division',
    dmsError: 'An error on the side of {0} occurred. Please try again later.',
    integrationSuspended: 'Export to {0} not currently available.<br>Please contact Activix Support for more information.',
    dmsErrorLead: '{0} experienced an error.<br>The lead {1} was not exported.',
    dmsErrorLeadLocked: '{0} experienced an error.<br>The lead {1} was not exported.<br>The deal in {0} is currently in use.',
    dmsErrorLeadStock: '{0} experienced an error.<br>The lead {1} was not exported.<br>Stock number already exists for another VIN.',
    dnd: 'DND.',
    doNotDisturb: 'Do not disturb',
    dndTitle: 'DND date',
    dndTooltip: 'Date until the client doesn\'t want to be disturbed',
    doNotMarket: 'Do not market',
    dollarAndLost: 'Dollar & Lost',
    download: 'Download',
    dropAttachment: 'Drop file here',
    duplicate: 'Duplicate',
    duplicateLead: 'Lead already exported.',
    durationTooltip: 'Response time<br>(from CRM)',
    edit: 'Edit',
    editing: 'Editing',
    editCommunication: 'Edit a communication',
    edited: 'Edited',
    email: 'Email',
    emailType: {
        home: 'Home email',
        work: 'Work email',
    },
    emailReadAt: 'Email read at',
    endContractDate: 'End contract date',
    endContractDateExchange: 'End contract date (E)',
    endContractRequired: 'The end contract date in the current vehicle is required to select the @:lexicon.portfolio.singular type',
    endServiceDate: 'End of service',
    exchanges: 'Exchanges',
    exchangeVehicle: 'Current vehicle',
    excluded: 'Excluded',
    exportedVehicleDisable: 'You cannot delete a vehicle that has been exported to {0}',
    exporting: 'Export in progress...',
    exportCompleted: 'Evaluation completed',
    exportTo: 'Export to',
    exportWaiting: 'Waiting',
    generatePdfTooltip: 'The ‘Primary Vehicle’ from the Lead will be used in PDF generation',
    goTo: 'Go to',
    exportToAutoVanceRequired: 'You must first export to Autovance',
    exportToDeskitRequired: 'You must first export to Deskit',
    fieldFromGroup: 'Field from the {0} group',
    files: 'Files',
    firstName: 'First name',
    firstUpdateTimeTooltip: 'First update time',
    followUp: 'FollowUp',
    freezedFinancialData: 'Lock financial data',
    financialDataLocked: 'Financial data are locked',
    financialAndDelivery: 'Financial & Delivery',
    funnel: 'Funnel',
    fusion: 'Merge',
    gas: 'Fueled',
    generalComment: 'Comments',
    generalInfo: 'General info',
    group: 'Group',
    guestMode: 'You are in "guest" mode',
    guestModeForDeliveryMen: 'You are in "guest" mode but you are the @:lexicon.delivery.singular person you can change the @:lexicon.delivery.singular fields.',
    guestModeWithAction: 'You are in "guest" mode, <br>to assign the lead to yourself, please try to contact the customer',
    hideSensitiveInfoMode: 'Hide sensitive information',
    history: 'History',
    included: 'Included',
    indefinitely: 'Indefinitely',
    info: 'Info',
    inspected: 'Inspected',
    institution: 'Institution',
    institutionVehicle: 'Institution',
    integrationLeadBlockedTooltip: 'Impossible to change lead type coming from an integration',
    invalid: 'Invalid',
    invalidDate: 'Invalid date',
    invalidLeadTypeForServiceLead: 'You cannot choose this type for a lead service',
    inventoryUnitId: 'Inventory unit',
    invoiced: 'Invoiced',
    isSubscribed: 'Subscribed',
    isNotSubscribed: 'Unsubscribed',
    isUnsubscribedAll: 'Unsubscribe all',
    keepThisLead: 'Create as new',
    keyword: 'Keyword',
    language: 'Language',
    lastName: 'Last name',
    lastPresentedDate: 'Last visit date',
    lastUpdated: 'Last updated',
    leadAlreadyAssigned: 'You are not assigned to this lead.',
    leadAssociated: 'Lead associated, redirecting',
    leadCannotHaveStatus: 'The lead cannot have a lost, duplicate or invalid status to access this option',
    leadCompleted: 'Lead completed, redirecting',
    leadCreated: 'Lead created, redirecting',
    leadForm: 'Form',
    leadManagement: 'Lead Management',
    leadMustBeSold: 'The lead must be @:lexicon.sold.singular to be @:lexicon.delivered.singular',
    leadMustBeRenewed: 'The lead must be @:lexicon.renewed.singular to be @:lexicon.delivered.singular',
    leadMustHaveASoldWantedVehicle: 'The lead must have a @:lexicon.sold.singular wanted vehicle to add a @:lexicon.delivered.singular date',
    leadRequirementForAction: 'The lead must be {0} to perform this action',
    leadxpressEmail: 'Email',
    legend: 'Legend',
    linkedDuplicate: 'Duplicates of this lead',
    longTerm: 'Long term',
    lost: 'Lost',
    loyalty: 'Loyalty',
    loyaltyTooltip: 'Loyalty Client',
    manualCall: 'Manual Call',
    manualSMS: 'Manual SMS',
    callLogCommunication: 'API Phone System',
    mergeFillTooltip: 'The "Complete" function only adds information to the empty CRM fields.',
    mergeLead: 'Merge',
    mergeLeadRenewNote: '* If an exchange vehicle from the source lead does not exist in the @:lexicon.portfolio.singular destination lead, a new @:lexicon.portfolio.singular lead will be created and linked to the destination lead.',
    mergeReplaceTooltip: 'The "Replace" function replaces the CRM data with the available data from the import for the competing fields without affecting the other fields.',
    messenger: 'Messenger',
    messengerDescription: 'Messenger conversation description',
    met: 'Met',
    minAssignReasonRequest: 'The reason must be at least 10 characters',
    modifyDeliveryDate: 'The @:lexicon.sale.singular must be unchecked to modify this field',
    more: 'More',
    msrp: '(MSRP)',
    mustBeDelivered: 'The lead must be @:lexicon.delivered.singular to access this option',
    name: 'Full name',
    navigationHistory: 'Navigation history',
    navigationHistoryAbr: '@:clientCard.navigationHistory',
    navigationHistoryClickMsg: 'Click to view navigation history',
    navigationHistoryEmpty: 'No navigation history',
    needAppointmentChecked: 'The appointment must be checked to modify this field',
    needAppointmentUnchecked: 'The appointment must be unchecked to modify this field',
    needCommercial: 'Contact us to activate the "F&I" module',
    needCsiUnchecked: 'CSI must be unchecked to modify this field',
    needDeliveredChecked: '@.capitalize:lexicon.delivered.singular must be checked to modify this field',
    needDeliveredUnchecked: 'The @:lexicon.delivery.singular must be unchecked to modify this field.',
    neededDataForRenewal: 'The auto @:lexicon.portfolio.singular requires an end contract date or the "Cash Deal" modality on the @:lexicon.sold.singular vehicle before this field can be modified.',
    needInvoicedChecked: 'Invoiced must be checked to modify this field',
    needModalityInfo: 'The @:lexicon.sold.singular vehicle modality must be a cash deal or have an end contract date set',
    needOneCurrentVehicle: 'A current vehicle with an end contract date or a \'Cash Deal\' modality is required to select the @:lexicon.portfolio.singular type',
    needPaymentAndFrequencyEmpty: 'The balance cannot be set manually when there are values in the payment and frequency fields',
    needPresentedChecked: 'The visit must be checked to modify this field.',
    needPresentedDealerChecked: 'A visit at the dealership is required to modify this field.',
    needPresentedUnchecked: 'The visit must be unchecked to modify this field',
    needRefinancedUnchecked: 'The Buy Out must be unchecked to modify this field',
    needRenewalChecked: 'The @:lexicon.portfolio.singular must be checked to modify this field',
    needRenewalUnchecked: 'The @:lexicon.portfolio.singular must be unchecked to modify this field.',
    needSaleByPhoneUnchecked: 'The phone @:lexicon.sale.singular must be unchecked to modify this field.',
    needSaleChecked: 'The @:lexicon.sale.singular must be checked to modify this field',
    needSaleUnchecked: 'The @:lexicon.sale.singular must be unchecked to modify this field.',
    needToBeCommercial: 'You must be a commercial manager to edit this field',
    needToBeDirector: 'You must be a manager to edit this field',
    needVehicleInfoAndStockForSale: 'You must fill the make, model and stock fields to modify this field',
    needVehicleInfoForSale: 'You must fill the make and model fields to modify this field',
    needVehicleInfoToSelectSoldVehicle: 'You must fill the make, model and stock fields to select this vehicle',
    newAssociate: 'Associate',
    newest: 'Newest',
    newLead: 'New lead',
    nextDashboardLead: 'Next dashboard lead',
    nextPresentedDate: 'Next revival',
    nextStep: 'Next step',
    noAttachments: 'No attachments',
    noComment: 'No note',
    noCommunication: 'No communication',
    noDescription: 'No description',
    noEmail: 'No email',
    noLeadFound: 'No duplicate match; creating the lead...',
    noMakeModel: 'No vehicle',
    noSelection: 'No selection',
    noSms: 'No SMS',
    notes: 'Notes',
    audioNotesFiles: 'Audio notes & files',
    noteDeleteMessage: 'Are you sure you want to delete this note?',
    notMet: 'Not met',
    notOnLeadRenewal: 'This field cannot be modify if the type of the lead is @:lexicon.portfolio.singular',
    notSold: 'Not @:lexicon.sold.singular',
    noAdvisorForExport: 'An associate must be assigned to export this lead',
    noAdvisorTitle: 'No associate',
    nonConsumerInitiated: 'Non-Consumer Initiated',
    noVehicle: 'No vehicle; click the + sign to add one',
    noVehicleFilter: 'No vehicles match the filter',
    numberMustBeMobile: 'The number must be a valid mobile phone.',
    odometerLastVisit: 'Last visit KM',
    oldest: 'Oldest',
    openAutoVance: 'Open Autovance',
    openDeskit: 'Open Deskit',
    openWorkOrderDate: 'W.O. opening',
    openWorkOrderDateTooltip: 'Work Order opening date',
    optOut: 'Opt-out',
    order: 'Order | Orders',
    orderEmpty: 'No Order',
    origin: 'Source',
    originalCreatedAt: 'Original creation date',
    originalEmail: 'See the original email',
    others: 'Others',
    paperwork: 'Paperwork',
    pbsVehicleNoVINText: 'Only vehicles with a VIN will be exported. Do you want to continue ?',
    pbsVehicleNoVINTitle: 'VIN required',
    pending: 'Pending',
    performance: 'Performances',
    personalInfo: 'Client\'s information',
    phone: 'Phone',
    phoneAbr: 'Tel',
    phoneAppointment: 'Call appointment',
    phoneType: {
        home: 'Home phone',
        cell: 'Mobile phone',
        work: 'Work phone',
    },
    plannedEmail: 'Planned email',
    plannedPickUpDate: 'Planned pick up',
    popular: 'Most popular',
    postalCode: {
        CA: 'Postal code',
        US: 'ZIP code',
    },
    preferredPhone: 'Preferred phone number',
    preferredEmail: 'Preferred email address',
    prepaid: 'Prepaid',
    prepaidTooltip: 'Prepaid Client',
    prepared: 'Prepared',
    prepared_work_order: 'P.W.O.',
    preparedWorkOrderPlaceholder: 'P.W.O. #12348900',
    preparedWorkOrderTooltip: 'Prepared work order',
    presented: 'Visit',
    presentedDate: 'Visit date',
    previousDashboardLead: 'Previous dashboard lead',
    primaryContact: 'Primary contact',
    primaryContactOptions: {
        main: 'Full name',
        business: 'Business name',
    },
    setPrimaryVehicleTooltip: 'Set as Primary Vehicle for PDF & Email Generation',
    unsetPrimaryVehicleTooltip: 'Unset as Primary Vehicle for PDF & Email Generation',
    private: 'Private',
    privateNote: 'Private note',
    privateNoteInfo: 'A private note can only be seen by yourself',
    process: 'Process',
    processService: 'Process (Service)',
    progress: 'Progress',
    promisedDate: 'Promised time',
    provider: 'Provider',
    province: {
        CA: 'Province',
        US: 'State',
    },
    push: 'Mobile',
    qualification: 'Qualification',
    rating: 'Evaluation',
    ratingComment: 'Comments',
    ratingDeleteMessage: 'Are you sure you want to delete this evaluation?',
    ratingModalTitle: 'Client evaluation',
    ratingTooltip: 'Client evaluation',
    reached: 'Reached',
    readOnly: 'Read Only',
    reassignedCommentTooltip: 'Assignment request comments can\'t be edited',
    record: 'Record',
    recordAgain: 'Redo recording',
    recordDelete: 'This will delete your recording.',
    referrer: 'Referrer',
    refinanced: 'Buy Out',
    refinancedTooltip: 'Option available only for vehicle with leasing modality.',
    refresh: 'Refresh',
    rejoint: 'Reached',
    renewal: '@.capitalize:lexicon.portfolio.singular',
    renewalSuccessful: '@.capitalize:lexicon.portfolio.singular lead created successfully.',
    renewed: '@.capitalize:lexicon.renewed.singular',
    repairDate: 'Repair order date',
    repairOrder: 'Repair order',
    reply: 'Reply',
    replyingTo: 'Replying to',
    reportedNotSold: 'Reported not @:lexicon.sold.singular',
    reportedSold: 'Reported @:lexicon.sold.singular',
    requestSource: 'Request source',
    resetCBBValue: 'Reset Canadian Black Book Value',
    resetCBBValueTitle: 'Do you wish to reset to the Canadian Black Book Value?',
    responseTimeNotApplicableMergedTooltip: 'The response time is not calculated when other leads have been merged.',
    restore: 'Restore',
    result: '@.capitalize:lexicon.result.singular',
    revivalService: 'Revival',
    roadTest: 'Test drive | Test drives',
    roadTestEmpty: 'No Test drive',
    roadTestAYL: 'Test drive - AYL',
    roadTestTDM: 'Test drive - TDM',
    sale: '@.capitalize:lexicon.sale.singular',
    saleProcess: 'Sale Process',
    saleByPhone: '@.capitalize:lexicon.sale.singular by phone',
    save: 'Save',
    searchingLead: 'Searching for existing leads',
    searchTerm: 'Search term',
    secondContact: '2nd contact',
    secondaryAdvisor: '@.capitalize:lexicon.secondaryAdvisor.singular',
    selectAction: 'Please choose the action to execute',
    selectCorrectVersion: 'Select the correct version',
    sertiPushNoPhoneText: 'The customer must have a phone number',
    sertiPushNoPhoneTitle: 'Phone number required',
    service: 'Service',
    serviceAdvisor: 'Service advisor',
    serviceAgent: 'Service agent',
    serviceIntervalKm: 'Interval',
    serviceMonthlyKm: 'Monthly KM',
    setNextPresentedDate: 'According to the interval and the monthly KM, the next visit date should be on the <strong>{0}</strong>. Do you want to change the current value of <strong>{1}</strong>?',
    sex: 'Gender',
    showAllAutomations: 'Show all automations',
    sms: 'SMS',
    smsDescription: 'Text message description',
    smsDisabled: 'SMS notifications has been deactivated by the request of the manager.',
    smsTheClient: 'SMS',
    sold: '@.capitalize:lexicon.sold.singular',
    soldPrimaryVehicleInformation: 'Designating a vehicle as sold will automatically set it as the Lead’s Primary Vehicle',
    soldPrimaryVehicleTooltip: 'A vehicle sold is automatically set as the Lead\'s Primary Vehicle',
    soldVehicleDisabled: 'You cannot delete a @:lexicon.sold.singular vehicle',
    source: 'Source',
    state: 'Approval',
    status: 'Status',
    stock: 'Stock',
    storage: 'Storage',
    subscribed: 'Opt-out',
    subscriptionManagement: 'Subscriptions Management',
    succefullyExported: 'Exported successfully',
    exportRequestInProgress: 'Lead export to {0} in progress',
    sureChangeLeadType: 'Changing the lead type may result in erroneous statistics. Are you sure you want to modify this field?',
    sureMerge: 'Merging these leads will be irreversible and may result in erroneous statistics. Are you sure you want to proceed?',
    takeOver: 'T.O.',
    takeOverBy: 'T.O. by',
    task: 'Task',
    taskOwner: 'Owner',
    technician: 'Technician',
    timeElapsed: 'Time elapsed',
    timeFrame: 'Purchase intent period',
    total: 'Total',
    twenty_four_hour: '24 hr',
    twentyFourHrTooltip: '24 hr',
    type: 'Type',
    unauthorizedAction: 'You are not allowed to perform this action',
    unauthorizedActionOnField: 'You are not allowed to update this field',
    unfreezedFinancialData: 'Unlock financial data',
    unknown: 'Unknown',
    unsubscribedEmail: 'Unsubscribed from email since {0}',
    unsubscribedPhone: 'Unsubscribed from call since {0}',
    unsubscribedSince: 'Unsubscribed since {0}',
    unsubscribedSms: 'Unsubscribed from SMS since {0}',
    updatedAt: 'Updated',
    updatedBy: 'Updated by',
    user: 'User',
    validateLead: 'Select a lead to associate or to merge or click "Cancel" before continuing',
    validating: 'Validating...',
    valueByCBB: 'The value was defined by CBB with the condition: {0}',
    vehicle: 'Vehicle',
    vehicleExchange: 'Vehicle (Current)',
    vehicleWanted: 'Vehicle (Wanted)',
    vehicleState: 'Vehicle State',
    vehicleEvaluate: 'Vehicle was evaluated',
    vehicleExchangeEndWarrantyDate: 'End warranty',
    vehicleExchangeExtendedWarranty: 'Extended warranty',
    vehicleExchangeLicensePlate: 'License plate',
    vehicleExchangePurchaseDate: 'Purchase date',
    vehicleExchangeRecall: 'Recall',
    vehicleExportedToSerti: 'Vehicule exported to Serti',
    verifiedSaleUneditable: 'This @:lexicon.sale.singular is verified',
    videoconference: 'Videoconference',
    videoconferenceDescription: 'Videoconference description',
    viewSensitiveInfoMode: 'View sensitive information',
    waitingSale: 'Waiting @:lexicon.sale.singular',
    waitingSaleDisabled: 'The lead should not be @:lexicon.delivered.singular',
    waitingSaleTooltip: 'Force this lead to be considered as a waiting @:lexicon.sale.singular',
    walkAround: 'Walk around',
    walkIn: '@.capitalize:lexicon.walkIn.singular',
    walkInDescription: 'Visit details',
    wantedVehicle: 'Wanted vehicle',
    workOrder: 'W.O. #',
    workOrderClosureDate: 'W.O. closure',
    workOrderPartialClosureDate: 'W.O. partial closure',
    workOrderTooltip: 'Work Order #',
    writeUp: 'Write-Up',
    you: 'You',

    assignation: {
        transferAssignedTooltip: 'Assigned : {0}',
        transferWaitingTooltip: 'Pending : {0}',
    },
    associatedLeads: {
        assignedToMe: 'Assigned to me',
        createAssociatedLead: 'Create associated lead',
        close: 'Close',
        customerSince: 'Customer since {0}',
        collapse: 'Collapse',
        expand: 'Expand',
        filterLeads: 'Filter leads',
        interDealerLeads: 'Inter-Dealer Leads',
        noVehicle: 'No vehicle',
    },
    boxes: {
        clickToCollapse: 'Click to collapse',
        clickToExpand: 'Click to expand',
        collapse: 'Collapse',
        expand: 'Expand',
        filtering: 'Filtering',
        filteringAndSorting: 'Filtering & Sorting',

        activities: {
            title: 'Tasks & Appointments',

            addActivity: 'Add a task or appointment',
            automated: 'Automated',
            canceled: 'Canceled',
            enableCalendarView: 'Enable calendar view',
            enableTimelineView: 'Enable timeline view',
            guests: 'Guest|Guests',
            pendingActivities: '{0} pending task or appointment|{0} pending tasks or appointments',
            taskCreator: 'Task creator',
            upcomingActivities: '{0} upcoming task or appointment|{0} upcoming tasks or appointments',
            view: 'View',

            filters: {
                activityType: 'Task or Appointment Type',
                leadType: 'Lead type',
                timelineGroup: 'Group',
            },

            typeAction: {
                appointment: 'Appt',
                call: 'Call',
                csi: 'CSI',
                delivery: 'Vehicle @:lexicon.delivery.singular',
                email: 'Email',
                other: 'Other',
                phone_appointment: 'Phone Appt',
                sms: 'SMS',
                virtual_appointment: 'Virtual Appt',
                test_drive: 'Test drive',
            },
        },
        commercialProducts: {
            title: 'F&I Profits',

            clientMet: 'Client met',
            clientNotMet: 'Client not met',
            commercialSpecifications: 'F&I Specifications',
            freezeFinancialData: 'Block financial data',
            included: 'Included',
            notIncluded: 'Not included',
            product: 'product',
            profit: 'profit',
            sold: 'Sold',
            soldProductsCount: 'Sold Products',
            specifications: 'Specifications',
        },
        communications: {
            title: 'Communications',

            automated: 'Automated',
            campaign: 'Campaign',
            clickedTime: 'Clicked {0} times',
            edit: 'Edit',
            emailReadOn: 'Email read on {0}',
            exchangeCount: 'Exchange count',
            manual: 'Manual',
            planned: 'Planned',
            read: 'Read',
            time: 'time | times',
            unread: 'Unread',
            view: 'View',

            conferences: {
                browserNotSupportingVideoPlaying: 'Your browser does not support video playing.',
                duration: 'Lasted {0}',
                join: 'Join',
                notAuthorizedToJoin: 'You are not authorized to join this videoconference',
                recorded: 'Rec',
                recordedVideoTooltip: 'The videoconference has been recorded',
                recordingAccessDenied: 'You do not have access to this recording',
                recordingVideoTooltip: 'The videoconference will be recorded',
                videoProcessing: 'Video Processing...',
            },

            filters: {
                automation: 'Automation',
                direction: 'Direction',
                emailAttachment: 'Email with attachment',
                method: 'Method',
                type: {
                    title: 'Type',

                    regular: 'Regular',
                },
            },

            ringcentralRecordings: {
                audioUnavailable: 'Audio unavailable or processing',
            },
        },
        financialAndDelivery: {
            title: 'Financial & @.capitalize:lexicon.delivery.singular',

            reportedOn: 'Reported on',

            progress: {
                approvalSent: 'Approval sent',
                clientApproved: 'Client approved',
                deliveryDateSet: '@.capitalize:lexicon.deliveryDate.singular set',
                deliveryDateSetWithDate: '@.capitalize:lexicon.deliveryDate.singular set for {0}',
                vehicleDelivered: 'Vehicle @:lexicon.delivered.singular',
                vehicleDeliveredWithDate: 'Vehicle @:lexicon.delivered.singular on {0}',
                vehicleInspected: 'Vehicle inspected',
                vehicleSold: 'Vehicle @:lexicon.sold.singular',
            },
        },
        general: {
            averageSpendingService: '$ per visit (service)',
            contactPreference: 'Contact Preference',
            details: 'Lead Details',
            generalInfo: 'General Information',
            identity: 'Identity',
            invalidPhoneNumber: 'This phone number is invalid',
            lastVisitService: 'Last visit (service)',
            moreOptions: 'More options',
            noBusiness: 'No business',
            noPreferences: 'No preference',
            validatingPhoneNumber: 'Validating phone number...',
            view: 'View',
            viewBreakdown: 'View Breakdown',

            tabs: {
                contact: 'Contact',
                general: 'General',
            },
        },
        notes: {
            title: 'Notes',

            deleteConfirmationSingleChild: 'Are you sure you want to delete this note and the associated one?',
            deleteConfirmationMultipleChildren: 'Are you sure you want to delete this note and the {0} associates ones?',
            editingThisNote: 'Editing this note',
            filters: {
                related: 'Only vehicle notes',
                leadType: 'Lead type',
                noteType: {
                    title: 'Note type',

                    all: 'All',
                    attachment: 'Files',
                    audio: 'Audio',
                    case: 'Case',
                    cases: 'Cases',
                    string: 'Notes',
                    instruction: 'Instructions',
                    reassign: 'Assignment request',
                },
            },
            notAvailableInEditMode: 'Not available in edit mode',
        },
        overview: {
            viewContact: 'View contact',
            priority: {
                high: 'High priority',
                normal: 'Normal priority',
            },
            noDescription: 'No description',
            noPlannedTask: 'No planned task',
            noExcerpt: 'No excerpt',
            noCommunication: 'No communication',
            noMileage: 'Mileage unknown',
        },
        revisionsLog: {
            title: 'History of changes',
        },
        revival: {
            title: 'Revival',
        },
        salesProgress: {
            title: 'Sales Progress',
            negativeEquity: 'Negative',
            positiveEquity: 'Positive',
            modal: {
                actions: {
                    markVehicleAs: 'Mark Vehicle As',
                },
                addVehicle: 'Add Vehicle',
                tabs: {
                    appraisal: 'Appraisal',
                    dealInformation: 'Deal Information',
                    vehicleDetails: 'Vehicle Details',
                },
                title: {
                    tradeInVehicle: 'Current Vehicle',
                    wantedVehicle: 'Deal',
                },
                section: {
                    additionalInformation: 'Additional Information',
                    appraisal: 'Appraisal',
                    customFields: 'Custom Fields',
                    existingPaymentsAndContracts: 'Existing Payments and Contract',
                    financialInformation: 'Financial Information',
                    financialAndWarranty: 'Financial & Warranty',
                    financial: 'Financial',
                    payment: 'Payment',
                    allowanceAndResidual: 'KM Allowance And Residual',
                    contractDetails: 'Contract Details',
                    overview: 'Overview',
                    priceAndContract: 'Price and contract',
                    pricingDetails: 'Pricing Details',
                    warranty: 'Warranty',
                    extendedWarranty: 'Extended Warranty',
                    serviceFollowUp: 'Service Follow-Up',
                    vehicleCondition: 'Vehicle Condition',
                    vehicleInformation: 'Vehicle Information',
                },
            },
            tabs: {
                deal: 'Deal',
                tradeIn: 'Trade-in',
                presentation: 'Presentation',
                billOfSale: 'Sale',
                financialAndInsuranceAndCredit: 'Credit',
                delivery: 'Delivery',
                custom: 'Custom',
            },
            presentation: {
                sections: {
                    dealershipPresentation: 'Dealership Presentation',
                    vehiclePresentation: 'Vehicle Presentation',
                    customFields: 'Custom Fields',
                },
                inProgressReason: 'It would become complete when the appointment and visit checkboxes are ticked off.',
            },
            billOfSale: {
                sections: {
                    billOfSale: 'Bill of Sale',
                    customerDeposit: 'Customer Deposit',
                    customFields: 'Custom Fields',
                },
                billOfSaleCreated: 'Bill of Sale created',
                billOfSaleSentToCustomer: 'Sent to customer',
                billOfSaleSignedByCustomer: 'Signed by customer',
                customerDepositReceived: 'Deposit received',
                customerReceiptSent: 'Receipt sent',
                inProgressReason: 'It would become complete when a vehicle is sold.',
            },
            deal: {
                sections: {
                    noDealTitle: 'No deals yet',
                    addVehicleText: 'Add a vehicle first to start building a deal',
                    addVehicleTextButton: 'Add Vehicle',
                    filterVehiclesTextButton: 'Filter & Sort',
                },
                inProgressReason: 'It would become complete when a vehicle is sold.',
                listing: {
                    title: 'Wanted Vehicles',
                    vehicleInformation: {
                        sold: 'Sold',
                        soldOn: 'Sold on',
                        payment: 'Payment',
                        rate: 'Rate',
                        term: 'Term',
                        modality: {
                            cash: 'Cash',
                            leasing: 'Lease',
                            financing: 'Finance',
                        },
                        frequency: {
                            bi_monthly: 'Bimonthly',
                            monthly: 'Month',
                            one_payment: 'One Payment',
                            two_weeks: 'Biweekly',
                            weekly: 'Weekly',
                        },
                    },
                    actions: {
                        title: 'Actions',
                        viewDeal: 'View Deal',
                        vehicleDetails: 'Vehicle Details',
                        deleteVehicle: 'Delete Vehicle',
                        setPrimaryVehicle: 'Set as Primary Vehicle',
                        unsetPrimaryVehicle: 'Unset as Primary Vehicle',
                        setPrimaryVehicleTooltip: 'Set as Primary Vehicle for PDF & Email Generation',
                        unsetPrimaryVehicleTooltip: 'Unset as Primary Vehicle for PDF & Email Generation',
                        soldPrimaryVehicleTooltip: 'A vehicle sold is automatically set as the Lead\'s Primary Vehicle',
                        markAsSold: 'Mark as Sold',
                    },
                },
                addVehicleChoices: {
                    newInventory: 'New Inventory',
                    usedInventory: 'Used Inventory',
                    addManually: 'Add a Vehicle Manually',
                },
            },
            financialAndInsuranceAndCredit: {
                sections: {
                    creditApplication: 'Credit Application',
                    customFields: 'Custom Fields',
                },
                lenderApproval: 'Approval',
                institution: 'Institution',
                clientApproval: 'Approved',
                refinanced: 'Refinanced',
                inProgressReason: 'It would become complete when the lender and client approvals are received.',
            },
            delivery: {
                sections: {
                    vehiclePreparation: 'Vehicle Preparation',
                    customerCommunication: 'Customer Communication',
                    customFields: 'Custom Fields',
                },
                gas: 'Fueled',
                inspected: 'Inspected',
                prepared: 'Prepared',
                preparedWorkOrder: 'Work Order',
                inProgressReason: 'It will become complete upon delivery of the vehicle.',
            },
            tradeIn: {
                inProgressReason: 'It will become complete when an offer is approved.',
                listing: {
                    title: 'Current Vehicles',
                    addVehicle: 'Add Vehicle',
                    vehicleInformation: {
                        equity: 'Equity',
                        appraisal: 'Appraisal value',
                        resale: 'Resale value',
                    },
                    actions: {
                        viewAppraisal: 'View Appraisal',
                        vehicleDetails: 'Vehicle Details',
                        deleteVehicle: 'Delete Vehicle',
                    },
                },
            },
        },
        salesProcess: {
            title: 'Sale Process',

            beBack: 'Be-Back',
            deliveryPrepDate: 'Vehicle here on',
            takeOver: 'Take-Over',
            waitingSale: 'Waiting @:lexicon.sale.singular',

            progress: {
                appointmentScheduled: 'Appointment scheduled',
                appointmentScheduledWithDate: 'Appointment scheduled on {0}',
                clientVisited: 'Client visited',
                clientVisitedWithDate: 'Client visited on {0}',
                leadAcquiredWithDate: 'Lead acquired on {0}',
                testDriveDone: 'Test drive done',
                testDriveDoneWithDate: 'Test drive done on {0}',
                vehicleSold: 'Vehicle @:lexicon.sold.singular',
                vehicleSoldWithDate: 'Vehicle @:lexicon.sold.singular on {0}',
            },
        },
        serviceProcess: {
            title: 'Service Process',

            description: 'Description',
            price: 'Price',
            product: 'Product',
            sold: 'Sold',
            time: 'Time',
        },
        serviceProducts: {
            title: 'Sold Services',

            totalTime: 'Total time',
            workOrder: 'Work Order #',
        },
        vehicles: {
            currentVehiclesTitle: 'Current Vehicle | Current Vehicles',
            wantedVehiclesTitle: 'Wanted Vehicle | Wanted Vehicles',
            noCurrentVehicleTitle: ' No Current Vehicle',
            noWantedVehicleTitle: 'No Wanted Vehicle',

            budgetRange: 'Budget range',
            damageValue: 'Damage value',
            details: 'Details',
            endExtendedWarrantyDate: 'End extended warranty',
            endExtendedWarrantyMileage: 'End extended warranty KM',
            filtersResetOnVehicleCreation: 'Current filters will be reset after adding the vehicle. Do you want to continue with the vehicle creation?',
            lengthRange: 'Length range',
            moreInfo: 'More info',
            negativeEquity: 'Negative equity',
            positiveEquity: 'Positive equity',
            pricingOverview: 'Pricing overview',
            serviceFollowUp: 'Service Follow-up',
            stockToBeDefined: 'Stock TBD',
            totalRepairs: 'Total repairs',
            yearsRange: 'Years range',

            contractWidget: {
                cashdeal: '{amount} cashdeal',
                missingContractInfo: 'Missing contract information',
                missingEndContractDate: 'Missing end contract date',
                missingFrequency: 'Missing frequency',
                missingPayment: 'Missing payment',
                missingPaymentInfo: 'Missing payment information',
                missingPrice: 'Missing price',
                missingRate: 'Missing rate',
                missingTerm: 'Missing term',
                noCashdown: 'No cashdown',
            },

            filters: {
                polestarVehicleState: 'State',
                sold: '@:clientCard.sold',
                stockNumber: '# Stock',
                stockState: 'Stock state',
                vin: 'VIN',
            },

            noModalityInfo: 'Unknown modalities',
            noVinInfo: 'Unknown VIN',

            tabs: {
                appraisal: 'Appraisal',
                info: 'Info',
                priceAndContract: 'Price & Contract',
                quote: 'Quote',
            },
        },
    },
    countries: {
        CA: 'Canada',
        US: 'United States',
    },
    customFieldSections: {
        group: {
            lead: 'Lead',
            vehicleExchange: 'Current vehicle',
            vehicleWanted: 'Wanted vehicle',

            short: {
                lead: 'Lead',
                vehicleExchange: 'V. actuel',
                vehicleWanted: 'V. recherché',
            },
        },

        billOfSale: '@:clientCard.boxes.salesProgress.tabs.billOfSale',
        delivery: '@:clientCard.boxes.salesProgress.tabs.delivery',
        financialAndInsuranceAndCredit: '@:clientCard.boxes.salesProgress.tabs.financialAndInsuranceAndCredit',
        generalInfo: '@:clientCard.generalInfo',
        performance: '@:clientCard.saleProcess',
        vehicleWantedInfo: '@:clientCard.boxes.vehicles.tabs.info',
        vehicleWantedPriceAndContract: '@:clientCard.boxes.vehicles.tabs.priceAndContract',
        vehicleExchangeInfo: '@:clientCard.boxes.vehicles.tabs.info',
        vehicleExchangePriceAndContract: '@:clientCard.boxes.vehicles.tabs.priceAndContract',
        vehicleExchangeAppraisal: '@:clientCard.boxes.vehicles.tabs.appraisal',
        process: '@:clientCard.financialAndDelivery',
        processService: '@:clientCard.processService',
        funnel: '@:clientCard.funnel',
        dollarAndLost: '@:clientCard.dollarAndLost',
        salePerformance: '@:clientCard.saleProcess',
        financialAndDelivery: '@:clientCard.financialAndDelivery',
    },
    event: {
        callCount: 'Call count',
        event: 'Event',
        segment: 'Segment',
    },
    emptyState: {
        activityTitle: 'No activities',
        activityDescription: 'There are no tasks related to this lead.',
        activityNoMatchDescription: 'There are no tasks matching the filters.',
        communicationTitle: 'No communications',
        communicationDescription: 'There are no communications related to this lead.',
        communicationNoMatchDescription: 'There are no communications matching the filters.',
        noteTitle: 'No notes',
        noteDescription: 'There are no notes related to this lead.',
        noteNoMatchDescription: 'There are no notes matching the filters.',
        revisionsLogTitle: 'No history of changes',
        revisionsLogDescription: 'There is no history of changes for this lead.',
        tradeInTitle: 'No Trade-In yet',
        tradeInDescription: 'Add a Vehicle first to get started',
        tradeInAction: 'Add a Vehicle',
        vehicleExchangeTitle: 'No current vehicle',
        vehicleExchangeDescription: 'This lead has no current vehicle.',
        vehicleExchangeNoMatchDescription: 'There are no current vehicle matching the filters.',
        vehicleWantedTitle: 'No wanted vehicle',
        vehicleWantedDescription: 'This lead has no wanted vehicle.',
        vehicleWantedNoMatchDescription: 'There are no wanted vehicle matching the filters.',
    },
    leadVehicleAccidented: {
        frontBumper: 'Front bumper',
        frontDoorDriver: 'Front door driver',
        frontDoorPassenger: 'Front door passenger',
        frontPanelDriver: 'Front panel driver',
        frontPanelPassenger: 'Front panel passenger',
        hood: 'Hood',
        others: 'Others',
        rearBumper: 'Rear bumper',
        rearDoorDriver: 'Rear door driver',
        rearDoorPassenger: 'Rear door passenger',
        rearPanelDriver: 'Read panel driver',
        rearPanelPassenger: 'Rear panel passenger',
        total: 'Total',
        windshield: 'Windshield',
    },
    products: {
        air_filter: 'Air Filter',
        alignment: 'Align.',
        anti_theft: 'Anti theft',
        body: 'Body',
        brakes: 'Brakes',
        burn_protection: 'Burn protection',
        chiselling: 'Chiselling',
        diagnostic: 'Diagnost.',
        event: 'Event',
        extended_warranty: 'Extended warranty',
        financing: 'Financing',
        financing_cash_back: 'Financing cash back',
        flame_quard_protection: 'Flame Guard protection',
        in_turn: 'Who\'s Next',
        injection: 'Injection',
        ins_filling: 'Replacement ins.',
        ins_health: 'Health insurance',
        ins_invalidity: 'Invalidity insurance',
        ins_life: 'Life insurance',
        ins_rental: 'Leasing insurance',
        leather_tissu_interior_treatment: 'Leather & cloth interior treatment',
        maintenance_a: 'Maint. A',
        maintenance_b: 'Maint. B',
        maintenance_c: 'Maint. C',
        maintenance_d: 'Maint. D',
        maintenance_recommended: 'Maint. Recomm.',
        mouse_repellent: 'Mouse repellent',
        niotext: 'NioText',
        oil_filter: 'Oil & Filter',
        other: 'Other',
        others: 'Others',
        paint_treatment: 'Paint treatment',
        parts: 'Parts',
        pef: 'PEF',
        pellicule: '3M pellicule',
        pep: 'PEP',
        pollen_filter: 'Pollen Filter',
        pre_paid_maintenance: 'PPM',
        renewal: '@.capitalize:lexicon.portfolio.singular',
        roof_treatment: 'Roof treatment',
        rustproofing: 'Rustproofing',
        sale_table: '@.capitalize:lexicon.sale.plural Board',
        seat_protection: 'Seat protection',
        service: 'Service',
        starter: 'Remote starter',
        tires: 'Tires',
        transmission: 'Transmiss.',
        walk_in: '@.capitalize:lexicon.walkIn.singular',
        wash: 'Wash',
        wheel_protection: 'Wheel protection',
        window_tint: 'Window tint',
        windshield_treatment: 'Windshield treatment',
    },
    provinces: {
        AB: 'Alberta',
        BC: 'British Columbia',
        MB: 'Manitoba',
        NB: 'New Brunswick',
        NL: 'Newfoundland',
        NS: 'Nova Scotia',
        NT: 'Northwest Territories',
        NU: 'Nunavut',
        ON: 'Ontario',
        PE: 'Prince Edward Island',
        QC: 'Quebec',
        SK: 'Saskatchewan',
        YT: 'Yukon',

        AK: 'Alaska',
        AL: 'Alabama',
        AR: 'Arkansas',
        AS: 'American Samoa',
        AZ: 'Arizona',
        CA: 'California',
        CO: 'Colorado',
        CT: 'Connecticut',
        DC: 'District of Columbia',
        DE: 'Delaware',
        FM: 'Federated States of Micronesia',
        FL: 'Florida',
        GA: 'Georgia',
        GU: 'Guam',
        HI: 'Hawaii',
        IA: 'Iowa',
        ID: 'Idaho',
        IL: 'Illinois',
        IN: 'Indiana',
        KS: 'Kansas',
        KY: 'Kentucky',
        LA: 'Louisiana',
        MA: 'Massachusetts',
        MD: 'Maryland',
        ME: 'Maine',
        MH: 'Marshall Island',
        MI: 'Michigan',
        MN: 'Minnesota',
        MO: 'Missouri',
        MP: 'Northern Mariana Islands',
        MS: 'Mississippi',
        MT: 'Montana',
        NC: 'North Carolina',
        ND: 'North Dakota',
        NE: 'Nebraska',
        NH: 'New Hampshire',
        NJ: 'New Jersey',
        NM: 'New Mexico',
        NV: 'Nevada',
        NY: 'New York',
        OH: 'Ohio',
        OK: 'Oklahoma',
        OR: 'Oregon',
        PA: 'Pennsylvania',
        PR: 'Puerto Rico',
        PW: 'Palau',
        RI: 'Rhode Island',
        SC: 'South Carolina',
        SD: 'South Dakota',
        TN: 'Tennessee',
        TX: 'Texas',
        UT: 'Utah',
        VA: 'Virginia',
        VI: 'Virgin Islands',
        VT: 'Vermont',
        WA: 'Washington',
        WI: 'Wisconsin',
        WV: 'West Virginia',
        WY: 'Wyoming',
    },
    services: {
        averageSpending: '$ per visit',
        averageSpendingTooltip: 'Average spending per visit',
        cleaning: 'Cleaned',
        inspection: 'Test drive / Inspection',
        intention: 'Intention',
        minutes: 'Minutes',
        note: 'Note',
        price: 'Price',
        fiProfit: 'F&I Profit',
        reachedClient: 'Reached client',
        soldServices: '@.capitalize:lexicon.sold.singular services',
        time: 'Time',
        transport: 'Transport',
        walkAround: 'Walk-around',

        transports: {
            courtesy: 'Courtesy',
            drop: 'Drop',
            limousine: 'Limousine',
            other: 'Other',
            renting: 'Renting',
            wait: 'Wait',
        },
        walkArounds: {
            a1: 'A1',
            brokenGlass: 'Broken glass',
            bump: 'Bump',
            cleaning: 'Cleaning',
            other: 'Other',
            scratch: 'Scratch',
        },
    },
    statusTooltip: {
        duplicate: 'You cannot change {0} for a duplicate lead',
        invalid: 'You cannot change {0} for an invalid lead',
        lost: 'You cannot change {0} for a lost lead',

        inputs: {
            appointment: 'the appointment date',
            approbation: 'the approbation',
            approved: 'the approved field',
            available: 'the availability date',
            beBack: 'the be back date',
            csi: 'the CSI date',
            dealerTour: 'the dealer tour field',
            deliverable: 'the date when the vehicle will be @:lexicon.deliverable.singular',
            delivered: 'the @:lexicon.delivered.singular date',
            delivery: 'the @:lexicon.deliveryDate.singular',
            deposit: 'the deposit field',
            gas: 'the gas field',
            inspected: 'the inspected field',
            institution: 'the institution',
            longTerm: 'the long term field',
            phoneAppointment: 'the phone appointment date',
            prepared: 'the prepared field',
            presented: 'the visit date',
            qualification: 'the qualification',
            recorded: 'the reported date',
            refinanced: 'the Buy Out date',
            roadTest: 'the test drive date',
            sale: 'the @:lexicon.sale.singular date',
            saleByPhone: 'the @:lexicon.sale.singular by phone date',
            takeOver: 'the turn over date',
            twentyFourHour: 'the 24 hr field',
            verified: 'the @:lexicon.sale.singular verification',
            walkAround: 'the walk-around',
            waitingSale: 'the waiting @:lexicon.sale.singular',
            writeUp: 'the write-up field',
        },
    },
    vehicles: {
        accessories: 'Accessories',
        accidented_damage_cost: 'Accident $',
        accidentedTooltip: 'Indicates if the actual vehicle has accident',
        actual_value: 'Actual value',
        actualValueTooltip: 'Actual resale value',
        addNewVehicle: 'New vehicle',
        addNewVehicleInventory: 'From inventory',
        allowed_mileage: 'Allowed KM',
        allowedMileageYearly: 'km / yr',
        atv: 'ATV',
        auto: 'Automatic',
        automotive: 'Automotive',
        average: 'Average',
        balance: 'Balance',
        balanceTooltip: 'Remaining installments x Payment',
        baseWarranty: 'Base warranty',
        between_21_30_feet: 'Between 21 & 30 feet',
        between_31_40_feet: 'Between 31 & 40 feet',
        bike: 'Bike',
        bi_monthly: 'Bimonthly',
        biMonthly: 'Bimonthly',
        biWeekly: 'Bi weekly',
        blower: 'Blower',
        boat: 'Boat',
        bodyStyle: 'Body style',
        bodyType: 'Body type',
        border_size: 'Border size',
        budget_max: 'Max. budget',
        budget_min: 'Min. budget',
        buyBack: 'Buy Out',
        calculator_value: 'Estimated',
        caravan: 'Caravan',
        cash: 'Cash',
        Cash: 'Cash',
        category: 'Category',
        categoryRV: 'Category RV',
        class_a_diesel: 'Class A Diesel',
        clean: 'Clean',
        color: 'Color',
        colorExterior: 'Exterior Color',
        colorInterior: 'Interior Color',
        condition: 'Condition',
        contractEnd: 'Contract end date',
        decodeVin: 'Decode VIN',
        description: 'Description',
        diesel: 'Diesel',
        division: 'Division',
        documentation: 'Documentation',
        drivingWheels: 'Drivetrain',
        electric: 'Electric',
        endWarrantyDate: 'End warranty',
        engine: 'Engine',
        equity: 'Equity',
        equityTooltip: 'Value - Balance - Residual',
        exteriorColor: 'Exterior color',
        extendedWarranty: 'Extended warranty',
        extra_clean: 'Extra clean',
        fifth_wheel: 'Fifth wheel',
        financing: 'Financing',
        Financing: 'Financing',
        frequency: 'Frequency',
        fuel: 'Fuel',
        gasoline: 'Gasoline',
        generator: 'Generator',
        hybrid: 'Hybrid',
        hybride: 'Hybrid',
        horsepower: 'Horse power',
        initialCash: 'Cashdown',
        inStock: 'In stock',
        intention: 'Intention',
        interiorColor: 'Interior color',
        in_service_date: 'In service Date',
        kmAllowance: 'KM Allowance',
        leaseTransfer: 'Lease transfer',
        leasing: 'Leasing',
        Leasing: 'Leasing',
        length: 'Length',
        link: 'Lien',
        location: 'Leasing',
        Loan: 'Loan',
        loan: 'Loan',
        make: 'Make',
        manual: 'Manual',
        mechanical: 'Mec. product',
        mileage: 'Odometer',
        mileageExcess: 'Est. mileage excess',
        mileageExcessTooltip: 'Estimated mileage excess at the end of the warranty',
        modality: 'Modality',
        model: 'Model',
        monthly: 'Monthly',
        Monthly: 'Monthly',
        months: 'months',
        monthsRemaining: 'Month remaining|Months remaining',
        paymentsRemaining: 'Payment remaining|Payments remaining',
        motoculteur: 'Rototiller',
        motorcycle: 'Motorcycle',
        motorized: 'Motorhome',
        motorized_a: 'Motorized A',
        motorized_b: 'Motorized B/B+',
        motorized_c: 'Motorized C',
        mower: 'Mower',
        msrp: 'MSRP',
        noEstimate: 'No estimated value',
        noInfo: 'This wanted vehicle does not have a Make, Model or Year saved',
        noStockNumber: 'No stock number',
        noTrim: 'No trim information',
        noWantedVehicle: 'This lead does not have a wanted vehicle',
        offer_number: 'Offer #',
        one_payment: 'One payment',
        onePayment: 'One payment',
        options: 'Options',
        order_number: 'Order #',
        outside: 'Outside',
        over_40_feet: '41 feet and over',
        payment: 'Payment',
        paymentAmount: 'Payment Amount',
        paymentDetails: 'Payment Details',
        paymentFrequency: 'Payment Frequency',
        paymentType: 'Payment Type',
        paymentWithTax: 'Payment with tax',
        preparation: 'Preparation',
        price: 'Price',
        profit: 'Profit',
        rate: 'Rate',
        refinance: 'Refinance',
        refuseToRenew: 'Refuse to @:lexicon.renewed.infinitive',
        renewal: '@.capitalize:lexicon.renewed.infinitive',
        requested: 'Requested',
        requestedValue: 'Consumer Ask',
        resaleValue: 'Resale value',
        residual: 'Residual',
        residualValue: 'Residual value',
        retailPrice: 'Retail price',
        return: 'Return',
        rough: 'Rough',
        saved_date: 'Reported on',
        securityDeposit: 'Security deposit',
        sequential: 'Sequential',
        sleeping: 'Sleeping',
        snowmobile: 'Snowmobile',
        soldInDifferentLead: 'This stock is marked as sold in a different lead',
        stock: 'Stock',
        stockState: 'Stock state',
        stock_number: 'Stock #',
        subtotal: 'Subtotal',
        suffix: 'Suffix',
        suv: 'SUV',
        tent_trailer: 'Tent trailer',
        term: 'Term',
        tire_type: 'Tires',
        tires: 'Tires $',
        tireTooltip: 'Tires included at the time of @:lexicon.sale.singular',
        tireTypeTooltip: 'Tires included with the current vehicle',
        torque: 'Torque',
        total: 'Total',
        totalAllowedMileage: 'Total KM allowance',
        trade_notes: 'Notes',
        trade_type: 'Buying',
        tradeIn: 'Trade-In',
        tradeTypeDisabledTooltip: 'A value must be entered before selecting this option.',
        tradeTypeTooltip: 'When selecting an option, the vehicle will be considered bought for statistical purposes.',
        trailer: 'Trailer',
        trailer_park: 'Trailer park',
        transmission: 'Transmission',
        travel_trailer: 'Travel trailer',
        trim: 'Trim',
        truck: 'Truck',
        Two_weeks: 'Two weeks',
        under_20_feet: '20 feet and under',
        unverify: 'Remove verification',
        utility: 'Utility (side by side)',
        value: 'Value',
        valueTooltip: 'Purchase value',
        van: 'Van',
        vAutoValue: '$ vAuto',
        vehicle_state: 'Vehicle State',
        vehicleStatus: 'Vehicle Status',
        verify: 'Mark as verified',
        verifiedTooltip: 'Verified @:lexicon.sale.singular',
        version: '@.capitalize:lexicon.version.singular',
        vin: 'VIN',
        vinDecodingFailed: 'Failed to decode VIN. Make sure the VIN is valid and has 17 characters.',
        warranty: 'Warranty',
        warranty_type: 'Warranty Type',
        water_pump: 'Water pump',
        watercraft: 'Watercraft',
        weekly: 'Weekly',
        Weekly: 'Weekly',
        weight: 'Weight',
        year: 'Year',
        year_max: 'Max. year',
        year_min: 'Min. year',
        estimated: 'Estimated',
        estimatedBy: 'Estimated by',
        received: 'Evaluation done by {0} on {1}',
        openStock: 'Choose a vehicle in stock',
        openStockCondition: 'A selected make is required to choose a vehicle in stock',
        stockTitle: 'Vehicle Inventory',
        noStockFoundForFilter: 'No vehicle found for {0} {1} {2}.',
        noStockFoundForFilterWithStock: 'No vehicle found for {0} {1} {2} with stock {3}.',
        noStockFoundForFilterOnlyStock: 'No vehicle found for stock {0}.',
        emptyInventory: 'No vehicle available in inventory.',
        searchingVehicles: 'Searching for vehicles...',
        trimMissing: 'No trim information',
        stockMissing: 'No stock number provided',
        transmissionMissing: 'No transmission information',
        drivetrainMissing: 'No drivetrain information',
        drivetrainTransmissionMissing: 'No drivetrain information',
        mileageMissing: 'No mileage information',
        unavailable: 'Unavailable',
        order: 'Order',
        locate: 'Locate',
        pending: 'Pending',
        building: 'Building',
        transport: 'In transit',
        arrived: 'Arrived',
        stockTBD: 'Stock to be determined',
        stockStatusTBD: 'Status to be determined',
        new: 'New',
        used: 'Used',
        certified: 'Certified',
        demo: 'Demo',
        invoice: 'Invoiced',
        production: 'In production',
        transit: 'In transit',
        hold: 'On hold',
        sold: 'Sold',
        noInventoryUnit: 'This vehicle is not from inventory',
        noStockOrMakeModelYear: 'This vehicle does not have a stock number or a make,, model and a year',
        noInventoryUnitFromSupplier: 'This vehicle is not from {0} inventory',
        noMakeModelYear: 'This vehicle is missing a make, model or a year',
        none: 'None',

        tireType: {
            all: 'All',
            summer: 'Summer',
            winter: 'Winter',
        },
        tradeTypes: {
            excluded: 'Excluded',
            lost: 'Lost',
            none: 'None',
            other: 'Other',
            recycled: 'Recycled',
            retail: 'Retail',
            return: 'Return',
            wholesale: 'Wholesale',
        },
        vinMissingDataModal: {
            title: 'Data to validate',
        },
    },
    unsubscribeModal: {
        call: 'This will re-enable calls for this lead.',
        email: 'This will re-enable emails for this lead.',
        sms: 'This will re-enable SMS for this lead.',
    },
    export: {
        noVehicleOption: 'Do not export this type of vehicle',
        noVehicleOptionEligible: 'No vehicle eligible',
        purchase: 'Purchase',
        lease: 'Lease',
        deal: 'Deal',
        quote: 'Quote',
        financeType: 'Financing type',
        dealType: 'Deal type',
        personal: 'Personal',
        business: 'Business',
        dealerTrade: 'Dealer Trade',
        wholesale: 'Wholesale',
        fleet: 'Fleet',
        internalTransfer: 'Internal Transfer',
        refinancing: 'Refinancing',
        PreOrder: 'Pre-order',
        booked: 'Booked',
        confirmed: 'Confirmed',
        loan: 'Loan',
        cash: 'Cash',
        balloon: 'Balloon',
        dealStatus: 'Status',
        frequencyType: {
            oneTime: 'One time',
            monthly: 'Monthly',
            weekly: 'Weekly',
            biWkeely: 'Bi-weeky',
            yearly: 'Yearly',
        },
        wantedVehicleToExport: 'Wanted vehicle to export',
        exchangeVehicleToExport: 'Exchange vehicle to export',
        noCdkInventoryUnit: 'Vehicle not from CDK inventory',
        noInventoryUnit: 'Vehicle not from {0} inventory',
    },
    blocked: {
        leadAlreadyHasAppointment: 'This lead already has an appointment',
        leadAlreadyPresented: 'This lead already has a visit',
        leadHasDelivery: 'This lead has a planned @:lexicon.delivery.singular',
        leadIsBeBack: 'This lead is a be-back',
        leadIsDelivered: 'This lead is @:lexicon.delivered.singular',
        leadIsRefinanced: 'This lead is a Buy Out',
        leadIsRenewed: 'This lead is @:lexicon.renewed.singular',
        leadIsServiceWithConfirmation: 'This lead is from the Service division and has a confirmed appointment',
        leadIsService: 'This is a Service lead',
        leadIsSold: 'This lead is @:lexicon.sold.singular',
        leadIsWalkIn: 'This lead is @:lexicon.walkIn.prefix',
        leadMustBeDelivered: 'The lead must be @:lexicon.delivered.singular',
        leadMustBeInvoiced: 'The lead must be invoiced',
        leadMustBeSold: 'The lead must be @:lexicon.sold.singular',
        leadMustBeRenewed: 'The lead must be @:lexicon.portfolio.singular',
        leadNeedVisit: 'A visit is required',
        leadNeedDivision: 'A division is required',

        needRenewedWantedVehicle: 'The lead must have a @:lexicon.renewed.singular wanted vehicle',
        needSoldWantedVehicle: 'The lead must have a @:lexicon.sold.singular wanted vehicle',
        needToBeDirector: 'You must be a manager to edit this field',
        needVehicleInfoAndStock: 'You must fill the make, model and stock fields to modify this field',
        needVehicleInfo: 'You must fill the make and model fields to modify this field',

        specificCreatedMethod: 'This field is not editable when the lead comes from "{0}"',
        readOnlyField: 'This field is not editable',
        tradeReport: 'You need the "Acquisitions Report" module',
        unauthorizedFieldUpdate: 'You do not have access to update this field',
        unauthorizedLeadUpdate: 'You do not have access to update this lead',
        vehicleAppraisalCompleted: 'The vehicle appraisal has been completed',
        vehicleExported: 'The vehicle has been exported',
        vehicleMustBeLeased: 'The vehicle must be leased',
        vehicleStock: 'The vehicle is linked to an inventory unit',

        leadStatus: {
            duplicate: 'This field is not editable on a duplicate lead',
            invalid: 'This field is not editable on an invalid lead',
            lost: 'This field is not editable on a lost lead',
        },

        userAssignation: {
            cannotModifyAlreadyAssignedUser: 'You cannot modify an already assigned user',
            reassignationInProgress: 'A user reassignation is in progress',
            selfAssignementForbidden: 'You cannot manually assign yourself',
        },
    },
    quote: {
        calculatePricing: 'Calculate pricing',
        calculatedPrice: 'Calculated price',
        priceResponse: 'The pricing response will be displayed here...',
        success: 'Quote successfully saved',
        getBillOfSale: 'Get bill of sale',
        billOfSale: 'Bill of sale',
        billOfSaleProductId: 'Bill of sale product id',
        billOfSaleResponse: 'The bill of sale response will be displayed here...',
    },
};
