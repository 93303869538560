import Enum from './Enum.js';

export default class AutomationActionType extends Enum {
    static get entries() {
        return {
            sendEmail: 'sendEmail',
            sendSms: 'sendSms',
            sendWebhook: 'sendWebhook',
            createTaskEvent: 'createTaskEvent',
            sendNotification: 'sendNotification',
            sendSystemNotification: 'sendSystemNotification',
            sendConsentDoubleOptIn: 'sendConsentDoubleOptIn',
        };
    }

    static get SEND_EMAIL() {
        return this.entries.sendEmail;
    }

    static get SEND_SMS() {
        return this.entries.sendSms;
    }

    static get SEND_WEBHOOK() {
        return this.entries.sendWebhook;
    }

    static get CREATE_TASK_EVENT() {
        return this.entries.createTaskEvent;
    }

    static get SEND_NOTIFICATION() {
        return this.entries.sendNotification;
    }

    static get SEND_SYSTEM_NOTIFICATION() {
        return this.entries.sendSystemNotification;
    }

    static get SEND_CONSENT() {
        return this.entries.sendConsentDoubleOptIn;
    }
}
