import Enum from './Enum.js';
import Supplier from './Supplier.js';

export default class CreatedMethods extends Enum {
    static get entries() {
        return {
            activix: 'activix',
            acura_canada: 'acura_canada',
            api: 'api',
            auto_renewal: 'auto_renewal',
            autosync: 'autosync',
            bmw_canada: 'bmw_canada',
            cdk: 'cdk',
            cloning: 'cloning',
            ct_wizard: 'ct_wizard',
            dealer_vu: 'dealer_vu',
            easydeal: 'easydeal',
            f_c_a_digital: 'f_c_a_digital',
            ford_direct: 'ford_direct',
            gm_canada: 'gm_canada',
            honda_canada: 'honda_canada',
            kia_digital: 'kia_digital',
            m_b_c_a_digital: 'm_b_c_a_digital',
            manual: 'manual',
            manual_import: 'manual_import',
            mitsubishi: 'mitsubishi',
            n_c_i_digital: 'n_c_i_digital',
            niotext: 'niotext',
            pbs: 'pbs',
            phone_system: 'phone_system',
            polaris_digital: 'polaris_digital',
            porsche_digital: 'porsche_digital',
            porsche_finder: 'porsche_finder',
            scan: 'scan',
            scraper: 'scraper',
            serti: 'serti',
            stellantis_digital: 'stellantis_digital',
            taq_auto: 'taq_auto',
            messenger: 'messenger',
            harley_davidson_digital: 'harley_davidson_digital',
            polestar: 'polestar',
            maserati_digital: 'maserati_digital',
            prebooking: 'prebooking',
            one_eighty: 'one_eighty',
            hyundai: 'hyundai',
            call_log: 'call_log',
            volkswagen: 'volkswagen',
        };
    }

    static get ACTIVIX() {
        return this.entries.activix;
    }

    static get ACURA_CANADA() {
        return this.entries.acura_canada;
    }

    static get API() {
        return this.entries.api;
    }

    static get AUTO_RENEWAL() {
        return this.entries.auto_renewal;
    }

    static get AUTOSYNC() {
        return this.entries.autosync;
    }

    static get BMW_CANADA() {
        return this.entries.bmw_canada;
    }

    static get CDK() {
        return this.entries.cdk;
    }

    static get CLONING() {
        return this.entries.cloning;
    }

    static get CT_WIZARD() {
        return this.entries.ct_wizard;
    }

    static get DEALER_VU() {
        return this.entries.dealer_vu;
    }

    static get EASYDEAL() {
        return this.entries.easydeal;
    }

    static get F_C_A_DIGITAL() {
        return this.entries.f_c_a_digital;
    }

    static get FORD_DIRECT() {
        return this.entries.ford_direct;
    }

    static get GM_CANADA() {
        return this.entries.gm_canada;
    }

    static get HONDA_CANADA() {
        return this.entries.honda_canada;
    }

    static get KIA_DIGITAL() {
        return this.entries.kia_digital;
    }

    static get M_B_C_A_DIGITAL() {
        return this.entries.m_b_c_a_digital;
    }

    static get MANUAL() {
        return this.entries.manual;
    }

    static get MANUAL_IMPORT() {
        return this.entries.manual_import;
    }

    static get MITSUBISHI() {
        return this.entries.mitsubishi;
    }

    static get N_C_I_DIGITAL() {
        return this.entries.n_c_i_digital;
    }

    static get NIOTEXT() {
        return this.entries.niotext;
    }

    static get PBS() {
        return this.entries.pbs;
    }

    static get PHONE_SYSTEM() {
        return this.entries.phone_system;
    }

    static get POLARIS_DIGITAL() {
        return this.entries.polaris_digital;
    }

    static get PORSCHE_DIGITAL() {
        return this.entries.porsche_digital;
    }

    static get PORSCHE_FINDER() {
        return this.entries.porsche_finder;
    }

    static get SCAN() {
        return this.entries.scan;
    }

    static get SCRAPER() {
        return this.entries.scraper;
    }

    static get SERTI() {
        return this.entries.serti;
    }

    static get STELLANTIS_DIGITAL() {
        return this.entries.stellantis_digital;
    }

    static get MESSENGER() {
        return this.entries.messenger;
    }

    static get HARLEY_DAVIDSON_DIGITAL() {
        return this.entries.harley_davidson_digital;
    }

    static get POLESTAR() {
        return this.entries.polestar;
    }

    static get MASERATI_DIGITAL() {
        return this.entries.maserati_digital;
    }

    static get TAQ_AUTO() {
        return this.entries.taq_auto;
    }

    static get ONE_EIGHTY() {
        return this.entries.one_eighty;
    }

    static get HYUNDAI() {
        return this.entries.hyundai;
    }

    static get CALL_LOG() {
        return this.entries.call_log;
    }

    static get VOLKSWAGEN() {
        return this.entries.volkswagen;
    }

    static get translationGroup() {
        return 'createdMethods';
    }

    static selectOptions(account) {
        return this.values
            .map(value => {
                let invalid = false;
                switch (value) {
                    case this.ACURA_CANADA:
                        if (!account.suppliers.some(s => s.id == Supplier.ACURA_CANADA)) {
                            invalid = true;
                        }
                        break;
                    case this.EASYDEAL:
                        if (!account.suppliers.some(s => s.id == Supplier.EASY_DEAL)) {
                            invalid = true;
                        }
                        break;
                    case this.F_C_A_DIGITAL:
                        if (!account.suppliers.some(s => s.id == Supplier.FCA_DIGITAL)) {
                            invalid = true;
                        }
                        break;
                    case this.STELLANTIS_DIGITAL:
                        if (!account.suppliers.some(s => s.id == Supplier.STELLANTIS_DIGITAL)) {
                            invalid = true;
                        }
                        break;
                    case this.FORD_DIRECT:
                        if (!account.suppliers.some(s => s.id == Supplier.FORD_DIRECT)) {
                            invalid = true;
                        }
                        break;
                    case this.GM_CANADA:
                        if (!account.suppliers.some(s => s.id == Supplier.GM_CANADA)) {
                            invalid = true;
                        }
                        break;
                    case this.HONDA_CANADA:
                        if (!account.suppliers.some(s => s.id == Supplier.HONDA_CANADA)) {
                            invalid = true;
                        }
                        break;
                    case this.M_B_C_A_DIGITAL:
                        if (!account.suppliers.some(s => s.id == Supplier.MBCA_DIGITAL)) {
                            invalid = true;
                        }
                        break;
                    case this.MITSUBISHI:
                        if (!account.suppliers.some(s => s.id == Supplier.MITSUBISHI)) {
                            invalid = true;
                        }
                        break;
                    case this.KIA_DIGITAL:
                        if (!account.suppliers.some(s => s.id == Supplier.KIA_DIGITAL)) {
                            invalid = true;
                        }
                        break;
                    case this.N_C_I_DIGITAL:
                        if (!account.suppliers.some(s => s.id == Supplier.NISSAN_DIGITAL)) {
                            invalid = true;
                        }
                        break;
                    case this.NIOTEXT:
                        if (!account.niotext) {
                            invalid = true;
                        }
                        break;
                    case this.PBS:
                        if (!account.suppliers.some(s => [Supplier.PBS, Supplier.PBS_WORK_ORDER].includes(s.id))) {
                            invalid = true;
                        }
                        break;
                    case this.PHONE_SYSTEM:
                        if (!account.phone_up) {
                            invalid = true;
                        }
                        break;
                    case this.PORSCHE_DIGITAL:
                    case this.PORSCHE_FINDER:
                        if (!account.suppliers.some(s => s.id == Supplier.PORSCHE_DIGITAL)) {
                            invalid = true;
                        }
                        break;
                    case this.HARLEY_DAVIDSON_DIGITAL:
                        if (!account.suppliers.some(s => s.id == Supplier.HARLEY_DAVIDSON_DIGITAL)) {
                            invalid = true;
                        }
                        break;
                    case this.POLESTAR:
                        if (!account.suppliers.some(s => s.id == Supplier.POLESTAR)) {
                            invalid = true;
                        }
                        break;
                    case this.MASERATI_DIGITAL:
                        if (!account.suppliers.some(s => s.id == Supplier.MASERATI_DIGITAL)) {
                            invalid = true;
                        }
                        break;
                    case this.DEALER_VU:
                        if (!account.suppliers.some(s => s.id == Supplier.DEALER_VU)) {
                            invalid = true;
                        }
                        break;
                    case this.POLARIS_DIGITAL:
                        if (!account.suppliers.some(s => s.id == Supplier.POLARIS_DIGITAL)) {
                            invalid = true;
                        }
                        break;
                    case this.ONE_EIGHTY:
                        if (!account.suppliers.some(s => s.id == Supplier.ONE_EIGHTY)) {
                            invalid = true;
                        }
                        break;
                    case this.SCAN:
                        if (!account.scan) {
                            invalid = true;
                        }
                        break;
                    case this.HYUNDAI:
                        if (!account.suppliers.some(s => s.id == Supplier.HYUNDAI)) {
                            invalid = true;
                        }
                        break;
                    case this.CALL_LOG:
                        if (!account.suppliers.some(s => s.id == Supplier.CALL_LOG)) {
                            invalid = true;
                        }
                        break;
                    case this.VOLKSWAGEN:
                        if (!account.suppliers.some(s => s.id == Supplier.VOLKSWAGEN)) {
                            invalid = true;
                        }
                        break;
                    case this.BMW_CANADA:
                        if (!account.suppliers.some(s => s.id == Supplier.BMW_CANADA)) {
                            invalid = true;
                        }
                        break;
                }

                return {
                    id: value,
                    label: this.getTranslation(value),
                    invalid,
                };
            })
            .sort((a, b) => a.label.localeCompare(b.label));
    }
}
